import React, { Component } from "react";
import ActionContainer from "../../../components/UIComponent/ActionTableComponent/actionContainer";
import ActionEditButton from "../../../components/UIComponent/ActionTableComponent/ActionEditButton";
import ActionDeleteButton from "../../../components/UIComponent/ActionTableComponent/ActionDeleteButton";
import { Navigate } from "react-router-dom";
import AuthenticationServices from "../../../services/axiosServices/apiServices/AuthenticationServices";
import SwalServices from '../../../services/swalServices/SwalServices';
import { APIURL, GetImage } from "../../../services/axiosServices/ApiEndPoints";
import { CommonSuccessMessages } from "../../../utils/Messages";
import { getAuthProps } from "../../../utils/AuthenticationLibrary";
import PageLoader from "../../../components/common/pageLoader/PageLoader";
import { encryptAES } from "../../../utils/Encryption";
import CreateButton from '../../../components/UIComponent/Buttons/CreateButton';
import WebsiteCmsServices from "../../../services/axiosServices/apiServices/WebsiteCmsServices";
import ButtonLoader from "../../../components/common/buttonLoader/ButtonLoader";
import Table from "../../../components/table/Table";
import ActionToggleButton from "../../../components/UIComponent/ActionTableComponent/ActionToggleButton";
import CommunitySpotlightServices from "../../../services/axiosServices/apiServices/CommunitySpotlightServices";

export default class ManageSpotlights extends Component {
    constructor(props) {
        super(props);
        this.websiteCmsServices = new WebsiteCmsServices();
        this.CommunitySpotlightServices = new CommunitySpotlightServices();
        this.swalServices = new SwalServices();
        this.state = {
            route: "",
            isLoading: false,
            isUpdateLoading: false,
            pagination: {
                pageNo: 1,
                pageSize: 50,
                orderByColumn: "",
                orderFlag: 0,
                searchText: "",
            },
            totalResultes: 0,
            publishedSpotlightList: [],
            deletedBy: 1,
            deletedAppId: 1,
            featuredWelcomeTitle: '',
        };
    }

    componentDidMount() {
        this.getPublishedSpotlightList();
    }

    getPublishedSpotlightList = (pagination = this.state.pagination) => {
        this.setState({ isLoading: true });
        this.websiteCmsServices.getPublishedSpotlightList(pagination).then((response) => {
            if (response.statusCode === 200 && response.responseItem != null) {
                const updatedList = response.responseItem.responseContent.itemList.map((item) => ({
                    ...item,
                    redirectionLink: item.redirectionLink && item.redirectionLink.trim() !== "" ? item.redirectionLink : "-",
                    IsActive: item.status === "Active", 
                }));
                this.setState({ publishedSpotlightList: updatedList });
                this.setState({ totalResultes: response.responseItem.responseContent.totalCount });
            }
            else {
                this.swalServices.Error(response.message);
            }
            this.setState({ isLoading: false });
        })
    }

    onDelete = (featuredNewMembersId) => {
        if (featuredNewMembersId !== null) {
            this.swalServices.Confirm(CommonSuccessMessages.Confirm_Question.replace("{0}", "Featured New Member"), CommonSuccessMessages.Confirm_Delete.replace("{0}", "member details"),
                CommonSuccessMessages.Yes_Text, CommonSuccessMessages.No_Text).then((response) => {
                    if (response) {
                        let request = {};
                        request.id = featuredNewMembersId;
                        request.deletedBy = this.state.deletedBy;
                        this.websiteCmsServices.deleteFeaturedNewMemberLogoById(request).then((response) => {
                            if (response.statusCode === 200 && response.responseItem != null) {
                                this.swalServices.Success("Featured New Member details deleted successfully.");
                                this.getPublishedSpotlightList();
                            }
                            else {
                                this.swalServices.Error(response.message);
                            }
                            this.setState({ isLoading: false });
                        })
                    }
                })
        }
        else {
            this.swalServices.Error("Sorry! You can't delete this Member.");
        }
    }

    handleChange(event) {
        const value = event.target.value;
        this.setState({ featuredWelcomeTitle: value });
    }

    searchByName = (value) => {
        let detail = this.state.pagination;
        detail.pageNo = 1;
        detail.pageSize = 10;
        detail.searchText = value;
        this.setState({ pagination: { ...detail } });
        this.getPublishedSpotlightList();
    }

    actions = (element, index, value) => {
        return (element !== "spotlightId") ? null
            :
            <td>
                <ActionContainer>
                    <ActionToggleButton
                        className="mr-2"
                        index={index}
                        data={this.state.publishedSpotlightList}
                        onToggleChage={this.onToggleChange.bind(this)}
                    />

                    <ActionEditButton
                        value={value}
                        id={index}
                        index={index}
                        onActionClick={this.onEdit}
                        text="Edit"
                    />
                    {/* <ActionDeleteButton
                        value={value}
                        id={index}
                        index={index}
                        onActionClick={this.onDelete}
                        text="Delete"
                    /> */}
                </ActionContainer>
            </td>
    }

    onEdit = (id) => {
        this.setState({ route: '/AddEditSpotlight/spotlightId?=' + encryptAES(id) });
    }

    setPagination = (newPagination) => {
        this.setState({ pagination: newPagination });
        // this.getbookingsCancellationList(newPagination);
    };

    onToggleChange = (index, event) => {
        let spotlightId = this.state.publishedSpotlightList[index].spotlightId;
        let statusId = 0
        if (this.state.publishedSpotlightList[index].status === "Inactive") {
            statusId = 1;
        }
        else if (this.state.publishedSpotlightList[index].status === "Active") {
            statusId = 0;
        }
        this.activeInActiveSpotlights(spotlightId, statusId);
    }

    activeInActiveSpotlights = (id, statusId) => {
        let request = {};
        request.id = id;
        request.statusId = statusId;

        if (request.id > 0 && request.statusId != null) {
            this.websiteCmsServices.activeInActiveSpotlights(request).then((response) => {
                if (response.statusCode === 200 && response.responseItem != null) {
                    if (response.responseItem.responseContent.hasError === false) {
                        if (statusId === 1) {
                            this.swalServices.SuccessWithAction("Spotlight has been published successfully", "OK").then((res) => {
                                this.getPublishedSpotlightList();
                            });
                        }
                        else {
                            this.swalServices.SuccessWithAction("Spotlight has been published successfully", "OK").then((res) => {
                                this.getPublishedSpotlightList();
                            });
                        }
                    }
                    else {
                        this.swalServices.Error(response.responseItem.responseContent.errorMessage);
                    }
                }
                else {
                    this.swalServices.Error(response.message);
                }
            });
        }
    }

    render() {
        if (this.state.route !== "") {
            return <Navigate to={this.state.route} />;
        }
        return (
            <div className="main-body-section">
                <div className="flex">
                    <div className="relative flex flex-col flex-1">
                        <main className="pb-10">

                            {/* Table Section */}
                            <div className="w-full mx-auto">
                                <div className="sm:flex sm:justify-between sm:items-center mb-8">
                                    <div className="mb-4 sm:mb-0">
                                        <h1 className="table-title-txt theme-color font-bold">
                                            {this.state.totalResultes} Published Spotlights
                                        </h1>
                                    </div>
                                    <CreateButton
                                        redirect="/AddEditSpotlight"
                                        text="Create New"
                                    />
                                </div>
                                <div className="relative" tyle={{ overflowX: 'auto', WebkitOverflowScrolling: 'touch', width: '100%' }}>
                                    <Table columns={[{ name: 'spotlightTitle', title: 'Spotlight Title' }, 
                                    // { name: 'redirectionLink', title: 'Redirection Link' },
                                    { name: 'orderingPosition', title: 'Ordering Position' }, { name: 'status', title: 'Status' }, { name: 'spotlightId', title: 'Action' }]}
                                        rows={this.state.publishedSpotlightList}
                                        isLoading={this.state.isLoading}
                                        sortingColumns={""}
                                        pagination={this.state.pagination}
                                        totalResultes={this.state.totalResultes}
                                        totalCount={this.state.totalResultes}
                                        setPagination={this.setPagination.bind(this)}
                                        customScope={[

                                            {
                                                column: 'spotlightId',
                                                renderTableData: this.actions.bind(this)
                                            },
                                        ]} 
                                        style={{ width: '100%', tableLayout: 'fixed' }}
                                        />
                                </div>
                            </div>
                        </main>
                    </div>
                </div>
            </div>
        );

    }
}
