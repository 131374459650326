import React from "react";
import Table from "../../../components/table/Table";
import ImageCropperWithUpload from "../../../components/ImageUpload/UploadImage";
import CommonServices from "../../../services/axiosServices/apiServices/CommonServices";
import SwalServices from "../../../services/swalServices/SwalServices";
import moment from "moment";
import WebsiteCmsServices from "../../../services/axiosServices/apiServices/WebsiteCmsServices";
import LeadershipPositionsServices from "../../../services/axiosServices/apiServices/LeadershipPositionsServices";
import { APIURL, GetImage } from "../../../services/axiosServices/ApiEndPoints";

export default class OrganisationChart extends React.Component {
    constructor(props) {
        super(props);
        this.commonServices = new CommonServices();
        this.swalServices = new SwalServices();
        this.websiteCmsServices = new WebsiteCmsServices();
        this.leadershipPositionsServices = new LeadershipPositionsServices();
        this.state = {
            organisationChartDetails: {
                organisationChartDetailsId: 0,
                chartImagePath: "",
                updatedAt: null,
            },
            chartFileName: "",

            pagination: {
                pageNo: 1,
                pageSize: 10,
                orderByColumn: "",
                orderFlag: 0,
                searchText: "",
                status: 0,
                filterByType: 0,
                filterByStatus: 0,
            },
            totalResultes: "",
            isLoading: false,
        }
    }



    saveMainImage = (file, keyId, imageIdentity) => {
        file.storagePath = "OrganisationChartImages";
        this.commonServices.storeBase64Image(file).then((response) => {
            if (response != null) {
                this.swalServices.Success("Image Uploaded");
                if (file.storagePath === "OrganisationChartImages") {
                    let detail = this.state.organisationChartDetails;
                    detail["chartImagePath"] = response.fileName;
                    this.setState({ organisationChartDetails: { ...detail } });
                    let fileName = response.fileName
                    this.setState({chartFileName : fileName})
                    let chartImagePath = this.state.organisationChartDetails.chartImagePath;
                    this.updateOrganisationChartDetails(chartImagePath);
                }
            }
            else {
                this.swalServices.Error(response.message);
            }
        })
    }

    getOrganisationChartDetails = (pagination = this.state.pagination) => {
        this.setState({ isLoading: true });

        this.leadershipPositionsServices.getOrganisationChartDetails(pagination).then((response) => {
            if (response.statusCode === 200 && response.responseItem != null) {
                const rawDetails = response.responseItem.responseContent;
                this.setState({ organisationChartDetails: rawDetails });
            } else {
                this.swalServices.Error(response.message);
            }
            this.setState({ isLoading: false });
        });
    };


    updateOrganisationChartDetails = (chartImagePath) => {
        let request = {chartImagePath: chartImagePath}
        this.setState({ isUpdateLoading: true });
        this.leadershipPositionsServices.updateOrganisationChartDetails(request).then((response) => {
            if (response.statusCode === 200 && response.responseItem != null) {
                this.setState({ isUpdateLoading: false });
                this.getOrganisationChartDetails();
            } else {
                this.swalServices.Error(response.message);
            }
            this.setState({ isUpdateLoading: false });
        });
    }
    
    componentDidMount() {
        this.getOrganisationChartDetails();
    }

    setPagination = (newPagination) => {
        this.setState({ pagination: newPagination });
        this.getOrganisationChartDetails();
    };

    render() {
        return (
            <>
                <div className="main-body-section">
                    <div className="bg-white">
                        <div className="xl:col-span-6 py-3 lg:col-span-6 col-span-12">
                            <div className="adminaccount-info-croper company-logo-cropper ml-auto mr-auto mt-8">
                                <ImageCropperWithUpload
                                    key="Main"
                                    height={250}
                                    width={350}
                                    isCheckValidation={true}
                                    MaxFileSize={2097152}
                                    MaxFileSizeMessage={"2MB"}
                                    onSaveImage={this.saveMainImage}
                                    imageIdentity="OrganisationChartImages"
                                    intitImage={this.state.organisationChartDetails.chartImagePath}
                                    aspactRatio={350 / 250}
                                    uploadBtn={"Update Organisation Chart Image"}
                                    dimensionText={
                                        "(Dimension: 200px by 200px Maximum Size: 2MB)"
                                    }
                                />
                            </div>
                        </div>
                    </div>

                    <main className="pb-10">
                        <div className="w-full mx-auto">
                            <div className="relative">
                                <div>
                                    <div className=" mt-6">
                                        {/* <Table
                                            columns={[
                                                { name: "updatedAt", title: "Last Updated Date" },
                                                { name: "chartImagePath", title: "Chart Image" },
                                            ]}
                                            pagination={this.state.pagination}
                                            rows={this.state.organisationChartDetails} // Pass the formatted data
                                            sortingColumns={["pagesMainModulesName"]}
                                            isLoading={this.state.isLoading}
                                            totalResultes={this.state.totalResultes}
                                            totalCount={this.state.totalResultes}
                                            setPagination={this.setPagination}
                                        /> */}

                                    </div>
                                </div>
                            </div>
                        </div>
                    </main>
                </div>
            </>
        );
    }
}