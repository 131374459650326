import React, { Component } from 'react'
import SwalServices from '../../../services/swalServices/SwalServices';
import LeadershipPositionsServices from '../../../services/axiosServices/apiServices/LeadershipPositionsServices';
import moment from 'moment';
import { Navigate } from 'react-router-dom';
import ValidationText from '../../../utils/validation/ValidationText';
import { isValidForm, validate } from "../../../utils/validation/CommonValidator";
import { CommonValidationMessages } from '../../../utils/Messages';
import MultiSelectDropdown from '../../../components/dropdown/MultiSelectDropdown';
import Option from '../../../components/dropdown/multiSelectDropdownOption/MultiSelectOption';
import ImageCropperWithUpload from '../../../components/ImageUpload/UploadImage';
import CommonServices from '../../../services/axiosServices/apiServices/CommonServices';
import { APIURL, GetImage } from '../../../services/axiosServices/ApiEndPoints';



export default class AddEditCouncilDetails extends Component {
    constructor(props) {
        super(props)
        this.LeadershipPositionsServices = new LeadershipPositionsServices();
        this.swalServices = new SwalServices();
        this.commonServices = new CommonServices();
        this.state = {
            redirect: null,
            councilDetails: {
                councilDetailsId: 0,
                firstName: "",
                lastName: "",
                designation: "",
                companyURL: "",
                imageUrl: "",
                description: "",
                positionId: 0,
                linkedin: "",
                updatedBy: 0,
                updatedAppId: 0,
                createdBy: 0,
                createdAppId: 0,

            },
            validationRule: {
                firstName: [
                    {
                        type: 'require',
                        message: CommonValidationMessages.FieldRequired.replace('{0}', 'First name'),
                    },
                ],
                lastName: [
                    {
                        type: 'require',
                        message: CommonValidationMessages.FieldRequired.replace('{0}', 'Last name'),
                    },
                ],
                designation: [
                    {
                        type: 'require',
                        message: CommonValidationMessages.FieldRequired.replace('{0}', 'designation')
                    }
                ],
                companyURL: [
                    {
                        type: 'require',
                        message: CommonValidationMessages.FieldRequired.replace('{0}', 'Company Name')
                    }
                ],

                imageUrl: [
                    {
                        type: 'require',
                        message: CommonValidationMessages.FieldRequired.replace('{0}', 'Image')
                    }
                ],
                positionId: [
                    {
                        type: 'require',
                        message: CommonValidationMessages.FieldRequired.replace('{0}', 'Position Id')
                    }
                ],
                linkedin: [
                    {
                        type: 'require',
                        message: CommonValidationMessages.FieldRequired.replace('{0}', ' linkedin')
                    }
                ],
            },
            validState: {
                isValid: true,
                error: {}
            },
        }
    }

    handleChange(event) {
        const value = event.target.value;
        const name = event.target.name;
        let detail = this.state.councilDetails;
        detail[name] = value;
        this.setState({ councilDetails: { ...detail } });
    }


    save = (value) => {
        let detail = this.state.councilDetails;
        detail.updatedBy = 115;
        detail.updatedAppId = 15
        this.setState({ councilDetails: { ...detail } });
        this.updateCouncilDetails();
        this.setState({ redirect: "/ManageCouncilDetails" });

    }

    saveMainImage = (file, keyId, imageIdentity) => {
        file.storagePath = "imageURL";
        this.commonServices.storeBase64Image(file).then((response) => {
            if (response != null) {
                this.swalServices.Success("Logo Uploaded");
                if (file.storagePath === "imageURL") {
                    let detail = this.state.councilDetails;
                    detail["imageUrl"] = response.fileName;
                    this.setState({ councilDetails: { ...detail } });
                }
            }
            else {
                this.swalServices.Error(response.message);
            }
        })
    }
    back = () => {
        this.setState({ redirect: "/ManageCouncilDetails" });
    }

    addNew = (value) => {
        let detail = this.state.councilDetails;
        if (this.isValidateAllFields()) {
            detail.createdBy = 115;
            detail.createdAppId = 15;
            this.setState({ councilDetails: { ...detail } });
            this.addCouncilDetails();
            this.setState({ redirect: "/ManageCouncilDetails" });
        }
    }

    addCouncilDetails = () => {
        this.LeadershipPositionsServices.addCouncilDetails(this.state.councilDetails).then((response) => {
            if (response.statusCode === 200 && response.responseItem != null && response.responseItem.responseContent.hasError !== true) {
                this.swalServices.Success("Council Details inserted successfully.");
            } else if (response.responseItem.responseContent.hasError === true && response.responseItem.responseContent.errorType === "EXISTS") {
                this.swalServices.Error(" " + response.responseItem.responseContent.errorMessage);
            }
            else {
                this.swalServices.Error(response.message); 
            }
        })
    }

    updateCouncilDetails = () => {
        this.LeadershipPositionsServices.updateCouncilDetails(this.state.councilDetails).then((response) => {

            if (response.statusCode === 200 && response.responseItem != null && response.responseItem.responseContent.hasError !== true) {
                this.swalServices.Success("Council Details updated successfully");
            } else if (response.responseItem.responseContent.hasError === true && response.responseItem.responseContent.errorType === "Duplicate Position") {
                this.swalServices.Error(" " + response.responseItem.responseContent.errorMessage);
            }
            else {
                this.swalServices.Error(response.message);
            }

        })
    }

    // getLeadershipChapters = () => {
    //     this.LeadershipPositionsServices.getLeadershipChapters().then((response) => {
    //         if (response.statusCode === 200 && response.responseItem != null) {
    //             this.setState({ leadershipChapters: response.responseItem.responseContent });
    //         }
    //         else {
    //             this.swalServices.Error(response.message);
    //         }
    //         this.setState({ isLoading: false });
    //     })
    // }


    // getCouncilDetailsById = (id) => {
    //     this.LeadershipPositionsServices.getCouncilDetailsById(id).then((response) => {
    //         if (response.statusCode === 200 && response.responseItem != null) {
    //             this.setState({ councilDetails: response.responseItem.responseContent });


    //         } else {
    //             this.swalServices.Error(response.message);
    //         }
    //         this.setState({ isLoading: false });
    //     })
    // }
    getCouncilDetailsById = (id) => {
        this.setState({ isLoading: true });

        this.LeadershipPositionsServices.getCouncilDetailsById(id)
            .then((response) => {
                if (response.statusCode === 200 && response.responseItem != null) {
                    const councilDetails = response.responseItem.responseContent;
                    const imageUrl = councilDetails.imageUrl;


                    this.setState({
                        councilDetails: councilDetails,
                    });

                    if (imageUrl) {
                        const src = `${APIURL}${GetImage}?type=imageURL&&fileName=${imageUrl}`;

                        this.setState({ imageSrc: src });
                    } else {
                        console.warn("Image URL is null or undefined.");
                    }
                } else {
                    console.error("Error response:", response.message);
                    this.swalServices.Error(response.message);
                }
            })
            .catch((error) => {

                console.error("Error fetching council details:", error);
            })
            .finally(() => {

                this.setState({ isLoading: false });
            });
    };



    validateField = (key) => {
        const newValidState = validate(key, this.state.councilDetails, this.state.validationRule, this.state.validState);
        this.setState({ validState: newValidState });
    }

    isValidateAllFields = () => {
        const newValidState = isValidForm(this.state.councilDetails, this.state.validationRule, this.state.validState);
        this.setState({ validState: newValidState });
        return newValidState.isValid;
    }

    componentDidMount() {
        let params = new URLSearchParams(window.location.search);
        let CouncilDetailsId = params.get("");
        if (CouncilDetailsId > 0) {
            this.getCouncilDetailsById(CouncilDetailsId)
        }

        // this.getLeadershipChapters();
        // this.getLeadershipCommittee();
    }

    render() {
        if (this.state.redirect != null) {
            return <Navigate to={this.state.redirect} />;
        }
        return (
            <div className='main-body-section'>
                <div className='bg-white'>
                    <div className='heading-part px-7 py-4 border-b'>
                        <h4 className='small-title font-bold theme-color'>Add Council Member Information</h4>
                    </div>
                    <div className="px-14 py-4">
                        <div className='my-10'>
                            <div className='grid grid-cols-12 gap-6'>
                                <div className='xl:col-span-6'>
                                    <div className='grid grid-cols-12 gap-6'>
                                        <div className=' xl:col-span-9'>
                                            <label className="filter-lable block font-medium mb-3 theme-color min-h-30" htmlFor="firstName">First Name <span className='text-[#C00000]'>*</span></label>
                                            <input id="default" className="form-input rounded-none w-full shadow-red py-3 text-lg" type="text" name="firstName" onChange={(event) => this.handleChange(event)} value={this.state.councilDetails.firstName} onBlur={() => this.validateField("firstName")} />
                                            <div><ValidationText error={this.state.validState.error.firstName} /></div>
                                        </div>
                                        <div className=' xl:col-span-9'>
                                            <label className="filter-lable block font-medium mb-3 theme-color min-h-30" htmlFor="lastName">Last Name <span className='text-[#C00000]'>*</span></label>
                                            <input id="default" className="form-input rounded-none w-full shadow-red py-3 text-lg" type="text" name="lastName" onChange={(event) => this.handleChange(event)} value={this.state.councilDetails.lastName} onBlur={() => this.validateField("lastName")} />
                                            <div><ValidationText error={this.state.validState.error.lastName} /></div>
                                        </div>
                                        <div className=' xl:col-span-9'>
                                            <label className="filter-lable block font-medium mb-3 theme-color min-h-30" htmlFor="default"> Designation <span className='text-[#C00000]'>*</span></label>
                                            <input id="default" className="form-input rounded-none w-full shadow-red py-3 text-lg" type="text" name="designation" onChange={(event) => this.handleChange(event)} value={this.state.councilDetails.designation} onBlur={() => this.validateField("designation")} />
                                            <div><ValidationText error={this.state.validState.error.designation} /></div>
                                        </div>
                                        <div className=' xl:col-span-9'>
                                            <label className="filter-lable block font-medium mb-3 theme-color min-h-30" htmlFor="default">Company Name<span className='text-[#C00000]'>*</span></label>
                                            <input id="default" className="form-input rounded-none w-full shadow-red py-3 text-lg" type="text" name="companyURL" onChange={(event) => this.handleChange(event)} value={this.state.councilDetails.companyURL} onBlur={() => this.validateField("companyURL")} />
                                            <div><ValidationText error={this.state.validState.error.companyURL} /></div>
                                        </div>
                                        <div className=' xl:col-span-9'>
                                            <label className="filter-lable block font-medium mb-3 theme-color min-h-30" htmlFor="default">Arrangement Position <span className='text-[#C00000]'>*</span></label>
                                            <input id="default" className="form-input rounded-none w-full shadow-red py-3 text-lg" type="text" name="positionId" onChange={(event) => this.handleChange(event)} value={this.state.councilDetails.positionId} onBlur={() => this.validateField("positionId")} />
                                            <div><ValidationText error={this.state.validState.error.positionId} /></div>
                                        </div>
                                    </div>
                                </div>
                                <div className='xl:col-span-6'>
                                    <div className='grid grid-cols-12 gap-6'>
                                        <div className='xl:col-span-3'>
                                        </div>
                                        <div className='xl:col-span-9 '>
                                            <div className='img-logo-upload text-center'>
                                                <ImageCropperWithUpload
                                                    key="Main"
                                                    height={400}
                                                    width={400}
                                                    isCheckValidation={true}
                                                    MaxFileSize={2097152}
                                                    MaxFileSizeMessage={"1MB"}
                                                    onSaveImage={this.saveMainImage}
                                                    imageIdentity="imageURL"
                                                    intitImage={this.state.councilDetails.imageUrl}
                                                    aspactRatio={400 / 400}
                                                    uploadBtn={"Upload Logo"}
                                                    dimensionText={
                                                        "(Dimension: 400px by 400px Maximum Size: 2MB)"
                                                    }
                                                />

                                                <ValidationText error={this.state.validState.error.imageUrl} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='xl:col-span-12'>
                                </div>
                                <div className='xl:col-span-12'>
                                    <div className='grid grid-cols-12 gap-6 mb-8'>
                                        <div className=' xl:col-span-5'>
                                            <label className="filter-lable block font-medium mb-3 theme-color min-h-30" htmlFor="default">Description </label>
                                            <input id="default" className="form-input rounded-none w-full shadow-red py-3 text-lg" type="text"
                                                name="description"
                                                onChange={(event) => this.handleChange(event)} value={this.state.councilDetails.description}
                                            />
                                        </div>
                                    </div>
                                    <div className='grid grid-cols-12 gap-6'>
                                        <div className=' xl:col-span-5'>
                                            <label className="filter-lable block font-medium mb-3 theme-color min-h-30" htmlFor="default">Linked In</label>
                                            <input id="default" className="form-input rounded-none w-full shadow-red py-3 text-lg" placeholder='' type="text"
                                                name="linkedin"
                                                onChange={(event) => this.handleChange(event)} value={this.state.councilDetails.linkedin}
                                                onBlur={() => this.validateField("linkedin")}
                                            />
                                            <div><ValidationText error={this.state.validState.error.linkedin} /></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <div className="flex flex-wrap justify-between space-x-10 mt-12">
                    <div>
                        <button className="w-32 px-7 py-2 btn-sm btn-gray text-lg text-white" onClick={() => this.back()}>Back</button>

                    </div>
                    {this.state.councilDetails.councilDetailsId ?
                        (
                            <div>
                                <button
                                    className="w-32 px-7 mr-16 py-2 btn-sm btn-lighblue text-lg text-white"
                                    onClick={() => this.save(5)}>
                                    Update
                                </button>
                            </div>
                        )
                        :
                        (
                            <div>
                                <button
                                    className="w-32 px-7 mr-16 py-2 btn-sm btn-lighblue text-lg text-white"
                                    onClick={() => this.addNew(5)}>
                                    Create
                                </button>
                            </div>
                        )
                    }

                </div>
            </div>
        )
    }
}
