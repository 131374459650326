

import React, { Component } from 'react'
import DropdownSelect from '../../../components/dropdown/Dropdown'
import { Link, Navigate } from "react-router-dom";
import SwalServices from '../../../services/swalServices/SwalServices';
import LeadershipPositionsServices from '../../../services/axiosServices/apiServices/LeadershipPositionsServices';
import CommonServices from '../../../services/axiosServices/apiServices/CommonServices';
import Table from '../../../components/table/Table'
import CreateButton from '../../../components/UIComponent/Buttons/CreateButton';
import { CommonSuccessMessages } from '../../../utils/Messages';
import { SuccessMessage } from '../../../utils/Messages';
import ActionEditButton from '../../../components/UIComponent/ActionTableComponent/ActionEditButton';
import ActionContainer from '../../../components/UIComponent/ActionTableComponent/actionContainer';
import ActionToggleButton from '../../../components/UIComponent/ActionTableComponent/ActionToggleButton';
import ActionDeleteButton from '../../../components/UIComponent/ActionTableComponent/ActionDeleteButton';
import { encryptAES } from '../../../utils/Encryption';


export default class ManageCommitteeMembers extends Component {

  editUser = (value, index) => {
    <Link to='/AddEditCommitteeMembers' />
  };

  constructor(props) {
    super(props)
    this.LeadershipPositionsServices = new LeadershipPositionsServices();
    this.CommonServices = new CommonServices();
    this.swalServices = new SwalServices();
    this.onPageChange = null;
    this.state = {
      totalResultes: "",
      getCommitteeDetailsList : [],
      status: [],
      setToggle2: false,
      redirect: null,
      isLoading: false,
      pagination: {
        pageNo: 1,
        pageSize: 10,
        orderByColumn: "",
        orderFlag: 0,
        searchText: "",
      },
      SearchBySGTechDesignation: [0],
      SearchByLeadershipStatus: [0]
    }
  }


  handleChange(event) {
    const value = event.target.value;
    const name = event.target.name;
    let detail = this.state.leadershipPosition;
    detail[name] = value;
    this.setState({ leadershipPosition: { ...detail } });
  }

  onEdit = (value, index) => {
    let editCommitteePosition = [...this.state.getCommitteeDetailsList];
    this.setState({ redirect: '/AddEditCommitteeMembers/committeeDetailsId?=' + encryptAES(editCommitteePosition[index].committeeDetailsId) });
  }

 

  onDelete = (value) => {

    let request = {
      id: value,
      deletedBy: 115,
      appId: 15,
    };
    this.swalServices
      .Confirm(
        CommonSuccessMessages.Confirm_Question.replace("{0}", "Committee Detail"),
        CommonSuccessMessages.Confirm_Delete.replace("{0}", "Committee Detail"),
        CommonSuccessMessages.Yes_Text,
        CommonSuccessMessages.No_Text
      )
      .then((response) => {
        if (response) {
          this.LeadershipPositionsServices.deleteCommitteeDetails(request)
            .then((response) => {
              console.log(response, "res")
              if (response.statusCode === 200) {
                this.swalServices.Success(
                  SuccessMessage.Delete_Success.replace("{0}", "Committee Detail")
                );
                this.getCommitteeDetailsList();
              } else {
                this.swalServices.Error(response.message);
              }
              this.setState({ isLoading: false });
            });
        }
      });

  };



  setFilterParameters = (id, drpIdentity) => {
    let detail = this.state.pagination;
    if (drpIdentity === "Statuses") {

      detail.SearchByLeadershipStatus = id;
      this.setState({ pagination: { ...detail } });
    }
    this.getLeadershipPositionsList();
  }


  handleChangeEvent = (value) => {
    let detail = this.state.pagination;
    detail.searchText = "";
    if (value.length > 2) {
      detail.searchText = value;
      this.setState({ pagination: { ...detail } });

    }
    this.getLeadershipPositionsList();
  }


  getCommitteeDetailsList = (pagination = this.state.pagination) => {
    this.setState({ isLoading: true });
    this.LeadershipPositionsServices.getCommitteeDetailsList(pagination).then((response) => {
      if (response.statusCode === 200 && response.responseItem != null) {
        this.setState({ getCommitteeDetailsList: response.responseItem.responseContent.itemList });
        this.setState({ totalResultes: response.responseItem.responseContent.totalCount })
      }
      else {
        this.swalServices.Error(response.message);
      }
      this.setState({ isLoading: false });
    })
  }

  setPagination = (newPagination) => {
    this.setState({ pagination: newPagination });
    this.getCommitteeDetailsList();
  };

  // setPagination = (newPagination) => {

  //   this.setState({ pagination: newPagination });
  //   // if (this.onPageChange) {
  //   //   this.onPageChange();
  //   // }
  //   this.getCommitteeDetailsList();
  // }
//   actions = (element, index, value) => {
//     return (element !== "leadershipPositionId") ? null
//       :
//       <td>
//       <ActionContainer>
//         {/* <ActionToggleButton
//             className="mr-2"
//             index = {index}
//             /> */}

//         <ActionToggleButton
//           className="mr-2"
//           index={index}
//           // toggle={this.state.toggle}
//           data={this.state.getLeadershipPositions}
//           // onToggleChage={this.onToggleChage.bind(this)}
//           onToggleChage=""
//         />

//         <ActionEditButton
//           value={value}
//           id={index}
//           index={index}
//           onActionClick={this.onEdit}
//           text="Edit"
//         />
//         <ActionDeleteButton
//           value={value}
//           id={index}
//           index={index}
//           onActionClick={this.onDelete}
//           text="Delete"
//         />
//       </ActionContainer>
//       </td>

//   }


actions = (element, index, value) => {
    return element !== "committeDetailsId" ? null : (
      <td>
      <ActionContainer>
        <ActionEditButton
          value={value}
          id={index}
          index={index}
          onActionClick={this.onEdit.bind(this)}
          text="Edit"
        />
        {/* {this.state.getResourcePagesList[index].pageTypeId === 3 ? ( */}
          <ActionDeleteButton
            value={value}
            id={index}
            index={index}
            onActionClick={this.onDelete}
            text="Delete"
          />
        {/* ) : null} */}
      </ActionContainer>
      </td>
    );
  };


  componentDidMount() {
    if (!this.onPageChange)
      this.onPageChange = this.getCommitteeDetailsList;
    this.getCommitteeDetailsList();
    }


  render() {
    if (this.state.redirect != null) {
      return <Navigate to={this.state.redirect} />;
    }
    return (
      <div className='main-body-section'>
        <div className="flex">
          <div className="relative flex flex-col flex-1 ">
            <main className='pb-10'>
              {/* <div className='custom-card shadow-lg mb-10'>
                <div className='filter-info grid grid-cols-12 gap-6'> */}
                  {/* <div className='filter-types xl:col-span-4'>
                    <label className="filter-lable block font-medium mb-3 theme-color min-h-30" htmlFor="default">Search by Title</label>
                    <input id="default" className="form-input rounded-none w-full shadow-red py-3 text-lg" type="text" onChange={(e) => this.handleChangeEvent(e.target.value)} />
                  </div> */}
                  {/* <div className='filter-types xl:col-span-4'>
                    <label className="filter-lable block font-medium mb-3 theme-color min-h-30" htmlFor="default">Category</label>
                    <DropdownSelect
                      value={this.state.SearchBySGTechDesignation} />
                  </div> */}
                  {/* <div className='filter-types xl:col-span-4'>
                    <label className="block text-xl font-medium  mb-3 theme-color min-h-30" htmlFor="default">Status</label>
                    <DropdownSelect
                      drpIdentity={"Statuses"}
                      optionArray={this.state.status}
                      setFilterParameters={this.setFilterParameters.bind(this)}
                      value={this.state.SearchByLeadershipStatus} />
                  </div> */}
{/* 
                </div>
              </div> */}
              <div className="w-full mx-auto">
                <div className="sm:flex sm:justify-between sm:items-center mb-8">
                  <div className="mb-4 sm:mb-0">
                    <h1 className="table-title-txt theme-color font-bold">
                     Total: {this.state.totalResultes} 
                    </h1>
                  </div>
                  {/* <div className="grid grid-flow-col sm:auto-cols-max justify-start sm:justify-end gap-3">
                    <Link to='/AddEditLeadershipPosition' >
                      <button className="btn btn-red text-white" >
                        <span className="text-xl text-white font-semibold tracking-normal"> Create New </span>
                      </button></Link>
                  </div> */}
                  <CreateButton
                    redirect='/AddEditCommitteeMembers'
                    text='Create New'
                  />
                </div>
                <div className="relative">
                  <div>
                    {/* Table */}
                    <div className="">
                      <Table columns={[{ name: 'committeeTitle', title: 'Title' },{ name: 'arrangementPosition', title: 'Arrangement Postion' }, { name: "committeDetailsId", title: "Action" },]}
                        rows={this.state.getCommitteeDetailsList}
                        isLoading={this.state.isLoading}
                        sortingColumns={["leadershipName", "statusName"]}
                        pagination={this.state.pagination}
                        totalResultes={this.state.totalResultes}
                        totalCount={this.state.totalResultes}
                        setPagination={this.setPagination.bind(this)}
                        customScope={[
                          {
                            column: 'committeDetailsId',
                            renderTableData: this.actions.bind(this)
                          },
                        ]} />
                    </div>
                  </div>
                </div>
              </div>
            </main>
          </div>
        </div>

      </div>
    )
  }

}
