import React, { Component } from "react";
import { Navigate } from "react-router-dom";
import Table from "../../../components/table/Table";
import SwalServices from "../../../services/swalServices/SwalServices";
import CorporateMemberServices from "../../../services/axiosServices/apiServices/CorporateMemberServices";
import moment from "moment";
import { saveAs } from "file-saver";

export default class MembershipTrackingReport extends Component {

  constructor(props) {
    super(props)
    this.corporateMemberServices = new CorporateMemberServices();
    this.swalServices = new SwalServices();
    this.state = {
      route: "",
      pagination: {
        pageNo: 1,
        pageSize: 10,
        orderByColumn: "CreatedAt",
        orderFlag: 0,
        searchText: "",
      },
      totalResultes: "",
      totalCount: "",
      isLoading: false,
      MembershipTrackingReportList: [],
      actionDropdown2: ["Download Data"],
    }
  }

  // Methods Section
  setPagination = (newPagination) => {
    this.setState({ pagination: newPagination });
  };

  actions = (element, index, value) => {
    return element !== "MembershipTrackingReportsId" ? null : (

      <td>
        {/* <ActionContainer> */}
        <button
          className="action-btn mx-auto flex items-center"
          aria-controls="add-edit-modal"
          onClick={() => {
            const row = this.state.MembershipTrackingReportList[index];
            this.downloadSheetForMembershipTrackingReport(value, row.generationDate,row.reportFor);
          }}
        >
          <p className="ml-2 tool-tip-txt underline text-[#81a3b8]">
            Download
          </p>
        </button>
        {/* </ActionContainer> */}
      </td>
    );
  };

  downloadSheetForMembershipTrackingReport = (MembershipTrackingReportsId, generationDate,reportFor) => {
    this.setState({ isLoading: true });
    let request = [];
    request.push(MembershipTrackingReportsId);
    request.push(reportFor)
    this.corporateMemberServices.downloadSheetForMembershipTrackingReport(request).then((response) => {
      if (response.status === 200 && response.data != null) {

        const formatter = new Intl.DateTimeFormat('en-GB', { month: 'long' });
        const month = formatter.format(new Date(generationDate));
        const year = new Date(generationDate).getFullYear();
        let blob = new Blob([response.data])
        saveAs(blob, `MembershipTrackingReport_${month}_${year}` + ".xlsx");
      }
      else {
        this.swalServices.Error(response.message);
      }
      this.setState({ isLoading: false });
    });
  }
  componentDidMount() {
    this.getAllMembershipTrackingReportList();
  }
  onClick = () => {
    this.getAllMembershipTrackingReportList();
  }

  getAllMembershipTrackingReportList = () => {
    this.setState({ isLoading: true });
    let pagination = { ...this.state.pagination };
 
    this.corporateMemberServices.getAllMembershipTrackingReportList(pagination).then((response) => {   
      if (response.statusCode === 200 && response.responseItem != null) {
        let details = response.responseItem.responseContent.itemList.map((item) => ({
          ...item,
          generationDate: moment(item.generationDate).format("DD MMM YYYY")
        }));
        this.setState({
          MembershipTrackingReportList: details,
          totalCount: response.responseItem.responseContent.totalCount,
          totalResultes: response.responseItem.responseContent.totalResultes
        })
      }
      else {
        this.swalServices.Error(response.message);
      }
      this.setState({ isLoading: false });
    });
  }

  searchByName = (value) => {
    let detail = this.state.pagination;
    detail.pageNo = 1;
    detail.pageSize = 10;
    detail.searchText = value;
    this.setState({ pagination: { ...detail } });
  }

  actionClick = (index, value, option, event) => {
    if (option === "Download Data") {
      let found = this.state.eventList[index];
      if (found) {
        this.getAllFormQuestionAnswerListByMembershipTrackingReportsId(found.MembershipTrackingReportsId, found.eventName);
      }
    }
  };

  handleChangeForPagination(event) {
    const value = event.target.value;
    const name = event.target.name;
    let detail = this.state.pagination;
    let fulldate = (detail[name] = value === "" ? null : value);
    let date = new Date(fulldate); //1/1/1753 12:00:00 AM and 12/31/9999 11:59:59 PM
    let startLimit = new Date("1/1/1753 12:00:00 AM");
    let endLimit = new Date("12/31/9999 11:59:59 PM");
    detail[name] = value;
    this.setState({ pagination: { ...detail } }, () => {
      if (startLimit < date) {
        // this.getAllTaskList();
      } else if (fulldate === null) {
        // this.getAllTaskList();
      }
    });
  }

  render() {
    if (this.state.route !== "") {
      return <Navigate to={this.state.route} />;
    }
    return (
      <div className="main-body-section">
        <div className="custom-card shadow-lg">
          <div className="filter-info grid grid-cols-12 gap-6">
            <div className="filter-types xl:col-span-5 lg:col-span-5 col-span-12 pr-12">
              <label
                className="filter-lable block text-xl font-medium  mb-1 theme-color min-h-30"
                htmlFor="default"
              >
                Date
              </label>
              {/* <input
                id="eventName"
                name="eventName"
                className="date-pickers form-input rounded-none w-full shadow-red py-3 text-lg"
                type="text"
                onChange={(e) => this.searchByName(e.target.value)}
              /> */}

              <input
                id="default"
                name="dateFrom"
                className="date-pickers form-input rounded-none w-full shadow-red py-3 text-lg"
                type="date"
                value={this.state.pagination.dateFrom}
                onChange={(e) => this.handleChangeForPagination(e)}
              />
            </div>

            <div className="xl:col-span-2 lg:col-span-2 col-span-12">
              <div className="flex items-end justify-end h-full pb-1">
                <button
                  className="btn btn-red text-white"  
                  onClick={() => this.onClick()}
                >
                  <span className="text-xl text-white font-semibold tracking-normal">
                    Search
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>

        {/* Event list section start */}
        <div className="grid grid-cols-6 gap-4 pt-10 px-8">
          <h2 className="col-start-1 col-end-3 text-3xl font-bold text-[#757575]">
            {`Total`}
          </h2>
        </div>
        <main className="pb-10">
          <div className="w-full mx-auto">
            <div className="relative">
              <div className="event-feedback-question-details">
                <div className=" mt-6">
                  <Table
                    columns={[
                      { name: "GenerationDate", title: "Generation Date" },
                      // { name: "numberOfRecords", title: "Number Of Records" },
                      { name: "MembershipTrackingReportsId", title: "Action" },
                    ]}
                    pagination={this.state.pagination}
                    rows={this.state.MembershipTrackingReportList}
                    sortingColumns={["GenerationDate"]}
                    isLoading={this.state.isLoading}
                    totalResultes={this.state.totalResultes}
                    totalCount={this.state.totalCount}
                    setPagination={this.setPagination.bind(this)}
                    customScope={[
                      {
                        column: "MembershipTrackingReportsId",
                        renderTableData: this.actions.bind(this),
                      },
                    ]}
                  />
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    );
  }
}