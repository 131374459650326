import moment from "moment";
import React, { Component } from "react";
import FinanceServices from "../../../services/axiosServices/apiServices/FinanceServices";
import SwalServices from "../../../services/swalServices/SwalServices";
import { saveAs } from "file-saver";
import PageLoader from "../../../components/common/pageLoader/PageLoader";
import Selector from "../../../components/dropdown/commonDropdown/Selector";
import { CommonValidationMessages } from "../../../utils/Messages";
import {
  isValidForm,
  validate,
} from "../../../utils/validation/CommonValidator";
import ValidationText from "../../../utils/validation/ValidationText";
import CorporateMemberServices from "../../../services/axiosServices/apiServices/CorporateMemberServices";
import DropdownSelect from "../../../components/dropdown/Dropdown";
import MultiSelectDropdown from "../../../components/inputs/MultiSelectDropdown";
import Option from "../../../components/dropdown/multiSelectDropdownOption/MultiSelectOption";

export default class EngagementReports extends Component {
  constructor(props) {
    super(props);
    this.financeServices = new FinanceServices();
    this.corporateMemberServices = new CorporateMemberServices();
    this.swalServices = new SwalServices();
    this.state = {
      engagementtype: [],
      companies: [],
      temp: {
        filterByEngagementType: []
      },
      engagementReportDetail: {
        filterByCompanyName: "",
        filterByEngagementType: "",
        filterByEngagementDateTo: "",
        filterByEngagementDateFrom: "",
      },
      pagination: {
        pageNo: 1,
        pageSize: 10,
        orderByColumn: "CreatedAt",
        orderFlag: 0,
        searchText: "",
      },
      engagementReportList: [],
      isLoading: false,
      totalCount: 0,
      validState: {
        isValid: true,
        error: {},
      },
      validationRule: {
        currentDate: [
          {
            type: "require",
            message: CommonValidationMessages.SelectRequired.replace(
              "{0}",
              "date for voucher code report"
            ),
          },
        ],
        voucherCodeUsedFor: [
          {
            type: "require",
            message: CommonValidationMessages.SelectRequired.replace(
              "{0}",
              "type of voucher code usage"
            ),
          },
        ],
      },
    };
  }

  componentDidMount = () => {
    this.getAllDropdownsForAllMemberReport();
    this.getAllEngagementReportList();
  };
  getAllDropdownsForAllMemberReport = () => {
    this.corporateMemberServices
      .getAllDropdownsForAllMemberReport()
      .then((response) => {
        if (response.statusCode === 200 && response.responseItem != null) {
          // let engagementtype = response.responseItem.responseContent.filter(
          //   (drp) => drp.dropdownName === "CorporateEngagementType"
          // );
          let companies = response.responseItem.responseContent.filter(
            (drp) => drp.dropdownName === "Companies"
          );
          let engagementtype = response.responseItem.responseContent
            .filter((drp) => drp.dropdownName === "CorporateEngagementType")
            .map((item) => ({ value: item.id, label: item.name }));
          this.setState({
            engagementtype: engagementtype,
            companies: companies,
          });
        } else {
          this.swalServices.Error(response.message);
        }
      });
  };


  // validateField = (key) => {
  //     const newValidState = validate(
  //         key,
  //         this.state.voucherReportRequest,
  //         this.state.validationRule,
  //         this.state.validState
  //     );
  //     this.setState({ validState: newValidState });
  // };

  // isValidateAllFields = () => {
  //     const newValidState = isValidForm(
  //         this.state.voucherReportRequest,
  //         this.state.validationRule,
  //         this.state.validState
  //     );
  //     this.setState({ validState: newValidState });
  //     return newValidState.isValid;
  // };

  getAllEngagementReportList = () => {
    this.setState({ isLoading: true });
    let pagination = { ...this.state.pagination };
    this.financeServices
      .getAllEngagementReportList(pagination)
      .then((response) => {
        if (response.statusCode === 200 && response.responseItem != null) {
          let details = response.responseItem.responseContent.itemList;
          this.setState({
            engagementReportList: details,
            totalCount: response.responseItem.responseContent.totalCount,
          });
        } else {
          this.swalServices.Error(response.message);
        }
        this.setState({ isLoading: false });
      });
  };

  handleChangeInDropdown(selected, drpIdentity) {
    if (drpIdentity === "OrganisationName") {
      let dataArr = { ...this.state.engagementReportDetail };
      let companyname = this.state.companies.find((x) => x.id === selected);
      if (companyname.id > 0) {
        dataArr.filterByCompanyName = companyname.id;
      } else {
        dataArr.filterByCompanyName = "";
      }
      this.setState({ engagementReportDetail: dataArr });
    }
  }

  handleChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;

    let date = this.state.engagementReportDetail;
    date[name] = value;
    this.setState({ engagementReportDetail: { ...date } });
  };

  generateEngagementReport = () => {
    // let isValidateAllFields = this.isValidateAllFields()
    // if (isValidateAllFields) {

    this.setState({ isLoading: true });
    let request = {};
    request.filterByEngagementDateTo = moment(
      this.state.engagementReportDetail.filterByEngagementDateTo
    ).format("DD MMM YYYY");
    request.filterByEngagementDateFrom = moment(
      this.state.engagementReportDetail.filterByEngagementDateFrom
    ).format("DD MMM YYYY");
    let compname = this.state.companies.find(
      (x) => x.id === this.state.engagementReportDetail.filterByCompanyName
    ).name;
    request.filterByCompanyName = compname;
    // let engagetype = this.state.engagementtype.find(
    //   (x) => x.id === this.state.engagementReportDetail.filterByEngagementType
    // ).name;
    // request.filterByEngagementType = engagetype;
    if (this.state.temp.filterByEngagementType.length > 0) {
      var arr1 = this.state.temp.filterByEngagementType.map(function (item) { delete item.bad; return item.value; });
      request.filterByEngagementType = arr1.join([","]);
    }

    this.financeServices.generateEngagementReport(request).then((response) => {
      if (response.statusCode === 200 && response.responseItem != null) {
        this.swalServices.Success("Engagement record generated successfully.");
        this.getAllEngagementReportList();
        this.reset();
      } else if (response.statusCode === 409) {
        this.swalServices.Error(response.message);
        this.reset();
      } else {
        this.swalServices.Error(response.message);
        this.reset();
      }
      this.setState({ isLoading: false });
    });

    // }
  };
  downloadSheetByEngagementReportId = (
    engagementReportId
  ) => {
    this.setState({ isLoading: true });
    let request = [];
    request.push(engagementReportId);
    this.financeServices
      .downloadSheetByEngagementReportId(request)
      .then((response) => {
        if (response.status === 200 && response.data != null) {

          let blob = new Blob([response.data]);
          // saveAs(blob, `${voucherCodeUsedFor}_VoucherReport_${month}_${year}` + ".xlsx");
          saveAs(
            blob,
            `EngagementReport_.xlsx`
          );
        } else {
          this.swalServices.Error(response.message);
        }
        this.setState({ isLoading: false });
      });
  };
  handleChangeMultiDropdown(selected, identity) {
    let temp = { ...this.state.temp };
    if (identity === "Engagement") {
      const maxSelections = 30;
      const limitedSelections = selected.slice(0, maxSelections);

      temp.filterByEngagementType = limitedSelections;

      this.setState({
        temp: temp,
        filterByEngagementType: limitedSelections,
      });

    }
  }
  render() {
    return (
      <div className="main-body-section finance-body-sec">
        <div className="bg-white">
          <div className="px-8 pt-10">
            <div className="grid grid-cols-12 gap-6 w-full items-center">
              <h2 className="text-xl theme-color mb-1  2xl:col-span-3 lg:col-span-3 col-span-3 w-full">
                Search by Company Name
              </h2>
              {/* <input
                id="default"
                name="filterByCompanyName"
                className="form-input rounded-none w-full shadow-red py-3 text-lg"
                type="text"
                value={this.state.engagementReportDetail.filterByCompanyName}
                onChange={(event) => this.handleChange(event)}
              /> */}
              <div className=" 2xl:col-span-9 lg:col-span-9 col-span-9 w-full">
                <DropdownSelect
                  drpIdentity="OrganisationName"
                  optionArray={this.state.companies}
                  allowSelectAll={false}
                  setFilterParameters={this.handleChangeInDropdown.bind(this)}
                  value={this.state.engagementReportDetail.filterByCompanyName}
                />
              </div>
            </div>
          </div>
          <div className="px-8 pt-10">
            <div className="grid grid-cols-12 gap-6 w-full items-center">
              <h2 className="text-xl theme-color mb-1 2xl:col-span-3 lg:col-span-3 col-span-3 w-full">
                Search by Engagement Type
              </h2>

              <div className="2xl:col-span-9 lg:col-span-9 col-span-9 w-full">
                {/* <DropdownSelect
                  drpIdentity="Engagement"
                  optionArray={this.state.engagementtype}
                  allowSelectAll={false}
                  setFilterParameters={this.handleChangeInDropdown.bind(this)}
                  value={
                    this.state.engagementReportDetail.filterByEngagementType
                  }
                /> */}
                <MultiSelectDropdown
                  drpIdentity="Engagement"
                  options={this.state.engagementtype}
                  isMulti
                  closeMenuOnSelect={false}
                  components={{ Option }}
                  hideSelectedOptions={false}
                  onChange={this.handleChangeMultiDropdown.bind(this)}
                  allowSelectAll={true}
                  // value={this.state.temp.memberStatus}
                  value={
                    this.state.temp.filterByEngagementType
                  }
                />
              </div>
            </div>
          </div>
          <div className="grid grid-cols-12 gap-6 w-full items-center px-8 py-10">
            <h2 className="text-xl theme-color mb-1 col-span-3">
              Search by Engagement Date
            </h2>
            <div className="col-span-9 grid grid-cols-12 gap-6">
              <div className="col-span-6 grid grid-cols-12 gap-6 items-center">
                <label
                  className="block text-xl font-medium 2xl:mb-1 lg:[0.25rem] theme-color min-h-30 col-span-2"
                  htmlFor="default"
                >
                  To<span className="text-[#C00000]">*</span>
                </label>
                <div className="col-span-10">
                  <input
                    id="filterByEngagementDateTo"
                    name="filterByEngagementDateTo"
                    className="date-pickers form-input rounded-none w-full shadow-red py-3 text-lg"
                    type="date"
                    value={moment(
                      this.state.engagementReportDetail.filterByEngagementDateTo
                    ).format("yyyy-MM-DD")}
                    onChange={(event) => this.handleChange(event)}
                  // onBlur={() => this.validateField("currentDate")}
                  //    disabled
                  />
                </div>
                <ValidationText
                  error={this.state.validState.error.currentDate}
                />
              </div>
              <div className="col-span-6 grid grid-cols-12 gap-6 items-center">
                <label
                  className="block text-xl font-medium 2xl:mb-1 lg:[0.25rem] theme-color min-h-30 col-span-2"
                  htmlFor="default"
                >
                  From<span className="text-[#C00000]">*</span>
                </label>
                <div className="col-span-10">
                  <input
                    id="filterByEngagementDateFrom"
                    name="filterByEngagementDateFrom"
                    className="date-pickers form-input rounded-none w-full shadow-red py-3 text-lg"
                    type="date"
                    value={moment(
                      this.state.engagementReportDetail
                        .filterByEngagementDateFrom
                    ).format("yyyy-MM-DD")}
                    onChange={(event) => this.handleChange(event)}
                  // onBlur={() => this.validateField("currentDate")}
                  //    disabled
                  />
                </div>
                <ValidationText
                  error={this.state.validState.error.currentDate}
                />
              </div>
            </div>

            <div className="col-span-4">
              <button
                className="btn btn-red text-xl border text-white mt-8"
                onClick={() => this.generateEngagementReport()}
              >
                Generate Engagement Report
              </button>
            </div>
          </div>
          <div className="grid grid-cols-12 gap-6 w-full items-center justify-center px-8 py-10">
            <div className="2xl:col-span-12 lg:col-span-12 col-span-12 w-full">
              {this.state.isLoading ? (
                <PageLoader />
              ) : (
                <table className="w-full mx-auto my-5 fixed_header batch-job-extraction-table border">
                  <thead>
                    <tr className="text-base font-bold flex">
                      <th className="px-2 first:pl-5 last:pr-5 tbl-th whitespace-nowrap text-white w-[40%]">
                        <div className="font-bold tble-th-txt text-left">
                          Generate Date
                        </div>
                      </th>
                      <th className="px-2 first:pl-5 last:pr-5 tbl-th whitespace-nowrap text-white w-[40%]">
                        <div className="font-bold tble-th-txt text-left">
                          EngagementDate To
                        </div>
                      </th>
                      <th className="px-2 first:pl-5 last:pr-5 tbl-th whitespace-nowrap text-white w-[40%]">
                        <div className="font-bold tble-th-txt text-left">
                        EngagementDate From
                        </div>
                      </th>
                      <th className="px-2 first:pl-5 last:pr-5 tbl-th whitespace-nowrap text-white w-[40%]">
                        <div className="font-bold tble-th-txt text-left">
                         Organisation Name
                        </div>
                      </th>

                      <th className="px-2 first:pl-5 last:pr-5 tbl-th whitespace-nowrap text-white w-[20%]">
                        <div className="font-bold tble-th-txt text-center">
                          Action
                        </div>
                      </th>
                    </tr>
                  </thead>
                  <tbody className="scrollit text-sm custom-tbody">
                    {this.state.engagementReportList.map((e, key) => {
                      return (
                        <tr className="custom-tbl-td flex items-center" key={key}>
                          <td className="px-2 first:pl-5 last:pr-5 whitespace-nowrap tble-td-txt text-[#757575] w-[40%] ">
                            {moment(e.generationDate).format("DD MMM YYYY")}
                          </td>
                          <td className="px-2 first:pl-5 last:pr-5 whitespace-nowrap tble-td-txt text-[#757575] w-[40%]">
                            {e.filterByEngagementDateTo}
                          </td>

                          <td className="px-2 first:pl-5 last:pr-5 whitespace-nowrap tble-td-txt text-[#757575] w-[40%]">
                            {e.filterByEngagementDateFrom}
                          </td>
                          <td className="px-2 first:pl-5 last:pr-5 whitespace-nowrap tble-td-txt text-[#757575] w-[40%]">
                            {e.filterByCompanyName}
                          </td>


                          <td className="px-2 first:pl-5 last:pr-5 whitespace-nowrap tble-td-txt text-[#757575] w-[20%]">
                            <button
                              className="action-btn mx-auto flex items-center"
                              aria-controls="add-edit-modal"
                              onClick={() => {
                                this.downloadSheetByEngagementReportId(e.engagementReportId);
                              }}
                            >
                              <p className="ml-2 tool-tip-txt underline text-[#81a3b8]">
                                Download
                              </p>
                            </button>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
