import { axiosGetAuthorize, axiosPostAuthorize } from '../AxiosRequests';
import { UpdateOrganisationChartDetails,GetOrganisationChartDetails, AddCouncilDetails, GetCouncilDetails, GetCouncilDetailsById, DeleteCouncilDetails, UpdateCouncilDetails, GetLeadershipChapters, GetLeadershipCommittee, GetAllSecretariatDetailsList,GetSecretariatDetailsById, AddSecretariatDetails, UpdateSecretariatDetails,CheckIfArrangementPositionExists, DeleteSecretariatDetails, GetCommitteeDetailsList, GetCommitteeDetailsById, AddEditCommitteeDetails, DeleteCommitteeDetails } from '../ApiEndPoints'

export default class LeadershipPositionsServices {

    async addCouncilDetails(request) {
        return axiosPostAuthorize(AddCouncilDetails, request)
    }

    async getCouncilDetails(request) {
        return axiosPostAuthorize(GetCouncilDetails, request)
    }

    async getCouncilDetailsById(request) {
        return axiosGetAuthorize(GetCouncilDetailsById, request)
    }

    async deleteCouncilDetails(request) {
        return axiosPostAuthorize(DeleteCouncilDetails, request)
    }

    async updateCouncilDetails(request) {
        return axiosPostAuthorize(UpdateCouncilDetails, request)
    }
    async getLeadershipChapters() {
        return axiosGetAuthorize(GetLeadershipChapters);
    }
    async getLeadershipCommittee() {
        return axiosGetAuthorize(GetLeadershipCommittee);
    }

    async getOrganisationChartDetails(request) {
        return axiosGetAuthorize(GetOrganisationChartDetails, request)
    }

    async updateOrganisationChartDetails(request) {
        return axiosPostAuthorize(UpdateOrganisationChartDetails, request)
    }

    async getAllSecretariatDetailsList(request) {
        return axiosPostAuthorize(GetAllSecretariatDetailsList, request)
    }

    async getSecretariatDetailsById(request) {
        return axiosGetAuthorize(GetSecretariatDetailsById, request)
    }
    async addSecretariatDetails(request) {
        return axiosPostAuthorize(AddSecretariatDetails, request)
    }
    async updateSecretariatDetails(request) {
        return axiosPostAuthorize(UpdateSecretariatDetails, request)
    }
    async deleteSecretariatDetails(request) {
        return axiosPostAuthorize(DeleteSecretariatDetails, request)
    }

    async getCommitteeDetailsList (request) {
        return axiosPostAuthorize(GetCommitteeDetailsList , request)
    }

    async getCommitteeDetailsById(request) {
        return axiosGetAuthorize(GetCommitteeDetailsById, request)
    }

    async addEditCommitteeDetails(request) {
        return axiosPostAuthorize(AddEditCommitteeDetails, request)
    }

    async deleteCommitteeDetails(request) {
        return axiosPostAuthorize(DeleteCommitteeDetails, request)
    }

    async checkIfArrangementPositionExists(request) {
        return axiosGetAuthorize(CheckIfArrangementPositionExists, request)
    }
}