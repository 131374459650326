import React, { useEffect, useState } from "react";
import ModalBasic from "../../component/FormModal";
import SectionWithBorder from "../../../components/membership/membershipTaskList/SectionWithBorder";
import CalculationChart from "../../../components/membership/membershipTaskList/CalculationChart";
import DropdownSelect from "../../../components/dropdown/Dropdown";
import moment from "moment";
import ValidationText from "../../../utils/validation/ValidationText";
import { StatusType } from "../../../utils/Constants";
import ButtonLoader from "../../../components/common/buttonLoader/ButtonLoader";
import { encryptAES } from "../../../utils/Encryption";
import MultiSelectDropdown from "../../../components/inputs/MultiSelectDropdown";
import Option from "../../../components/dropdown/multiSelectDropdownOption/MultiSelectOption";
import Table from "../../../components/table/Table";
import SingleSelectWithSearch from "../../../components/dropdown/SingleSelectWithSearch/SingleSelectWithSearch.js"

import DatePicker from "react-flatpickr";



const MembershipTaskListProcess = (props) => {
  const handleChangeInPriceTable = (value, name, year, tableName) => {
    props.handleChangeInPriceTable(value, name, year, tableName);
  };

  // const [endorserBothValid, setEndorserBothValid] = useState(false);
  // useEffect(() => {
  //   if (props.taskDetails.endorsementSatusForEndorser1 && props.taskDetails.endorsementSatusForEndorser2 === 'Approved'
  //   ) {
  //     setEndorserBothValid(true);
  //   } else {
  //     setEndorserBothValid(false);
  //   }
  // }, [props.taskDetails.endorsementSatusForEndorser1, props.taskDetails.endorsementSatusForEndorser2]);
  return (
    <ModalBasic
      id="add-edit-modal"
      className="membership-add-edit-modal"
      modalOpen={props.setAddeditModalOpen}
      setModalOpen={props.setModalOpen}
      title="Membership Task List Process"
    >
      {/* Modal content */}
      <div className="2xl:px-14 lg:px-3 pt-4">
        <div className="">
          <div className="relative">
            <SectionWithBorder title="Account Information" >
              <div className="">
                <div className="grid grid-cols-12 gap-6 w-full items-center pt-7 pb-0">
                  <div className="xl:col-span-6 md:col-span-6 col-span-12">
                    <div className="grid grid-cols-12 gap-6">
                      <div className="xl:col-span-12 md:col-span-12 col-span-12">
                        <label
                          className="filter-lable block font-medium 2xl:mb-1 lg:[0.25rem] theme-color min-h-30"
                          htmlFor="default"
                        >  Membership Type <span className="text-[#C00000]">*</span>
                        </label>
                        <DropdownSelect
                          drpIdentity={"MembershipType"}
                          optionArray={props.membershipType}
                          setFilterParameters={props.setFilterParameters}
                          value={props.taskDetails.membershipTypeId ? props.taskDetails.membershipTypeId : 0}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="xl:col-span-6 md:col-span-6 col-span-12">
                    <div className="grid grid-cols-12 gap-6">
                      <div className="xl:col-span-12 md:col-span-12 col-span-12">
                        <label
                          className="filter-lable block font-medium 2xl:mb-1 lg:[0.25rem] theme-color min-h-30"
                          htmlFor="default"
                        >
                          Transaction Type <span className="text-[#C00000]"> *</span>
                        </label>
                        <DropdownSelect
                          drpIdentity={"TransactionTypes"}
                          optionArray={props.transactionTypes}
                          setFilterParameters={props.setFilterParameters}
                          value={props.taskDetails.transactionTypeId ? props.taskDetails.transactionTypeId : 1}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="xl:col-span-6 md:col-span-6 col-span-12">
                    <div className="grid grid-cols-12 gap-6">
                      <div className="xl:col-span-12 md:col-span-12 col-span-12">
                        <label
                          className="filter-lable block font-medium 2xl:mb-1 lg:[0.25rem] theme-color min-h-30 "
                          htmlFor="default"
                        >
                          Subscription Start Date
                          <span className="text-[#C00000]"> *</span>
                        </label>
                        <div className="grid grid-cols-12 gap-6 items-center w-full">
                          <div className=" 2xl:col-span-12 lg:col-span-12 col-span-12 w-full">
                            <input
                              id="default"
                              className="date-pickers form-input rounded-none w-full shadow-red py-3 text-lg"
                              type="date"
                              name="subscriptionStartDate"
                              max={moment(new Date()).format("yyyy-MM-DD")}
                              value={props.taskDetails.subscriptionStartDate ? moment(props.taskDetails.subscriptionStartDate).format("yyyy-MM-DD") : props.taskDetails.subscriptionStartDate}
                              onChange={(event) => props.handleChange(event)}
                            // disabled={true}
                            />
                            <div>
                              <ValidationText error={props.validState.error.subscriptionStartDate} />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>
                  {props.taskDetails.transactionTypeId === 3 ? <div className="xl:col-span-6 md:col-span-6 col-span-12">
                    <div className="grid grid-cols-12 gap-6">
                      <div className="xl:col-span-12 md:col-span-12 col-span-12">
                        <label
                          className="filter-lable block font-medium 2xl:mb-1 lg:[0.25rem] theme-color min-h-30"
                          htmlFor="default"
                        >
                          Subscription End Date
                          {/* <span className="text-[#C00000]"> *</span> */}
                        </label>
                        <div className="grid grid-cols-12 gap-6 items-center w-full">
                          <div className=" 2xl:col-span-12 lg:col-span-12 col-span-12 w-full ">
                            <input
                              id="default"
                              name="subscriptionEndDate"
                              className={`date-pickers form-input rounded-none w-full py-3 text-lg`}
                              type="date"
                              value={`${new Date().getFullYear() + 1}-12-31`}
                              onChange={(event) => props.handleChange(event)}
                              disabled={true}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div> : <div className="xl:col-span-6 md:col-span-6 col-span-12">
                    <div className="grid grid-cols-12 gap-6">
                      <div className="xl:col-span-12 md:col-span-12 col-span-12">
                        <label
                          className="filter-lable block font-medium 2xl:mb-1 lg:[0.25rem] theme-color min-h-30"
                          htmlFor="default"
                        >
                          Subscription End Date
                          {/* <span className="text-[#C00000]"> *</span> */}
                        </label>
                        <div className="grid grid-cols-12 gap-6 items-center w-full">
                          <div className=" 2xl:col-span-12 lg:col-span-12 col-span-12 w-full ">
                            <input
                              id="default"
                              name="subscriptionEndDate"
                              className={`date-pickers form-input rounded-none w-full py-3 text-lg`}
                              type="date"
                              // value={`${new Date().getFullYear()}-12-31`}
                              value={props.taskDetails.subscriptionEndDate ? moment(props.taskDetails.subscriptionEndDate).format("yyyy-MM-DD") : `${new Date().getFullYear()}-12-31`}
                              onChange={(event) => props.handleChange(event)}
                              disabled={true}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>}


                  <div className="xl:col-span-6 md:col-span-6 col-span-12">
                    <div className="grid grid-cols-12 gap-6">
                      <div className="xl:col-span-12 md:col-span-12 col-span-12">
                        <label
                          className="filter-lable flex w-full font-medium 2xl:mb-1 lg:[0.25rem] theme-color min-h-30"
                          htmlFor="default"
                        >
                          Last Updated Date
                          {/* <span className="text-[#C00000]"> *</span> */}
                        </label>
                        <div className="grid grid-cols-12 gap-6 items-center w-full">
                          <div className=" 2xl:col-span-12 lg:col-span-12 col-span-12 w-full">
                            <input
                              id="default"
                              name="lastUpdatedDate"
                              className={`date-pickers form-input rounded-none w-full py-3 text-lg`}
                              type="date"
                              value={props.taskDetails.lastUpdatedDate ? props.taskDetails.lastUpdatedDate : new Date().toISOString().split('T')[0]}
                              onChange={(event) => props.handleChange(event)}
                            // onBlur={() => props.validateField("publishDate")}
                            />
                            {/* <div>
                          <ValidationText error={props.validState.error.publishDate} />
                          </div> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="xl:col-span-6 md:col-span-6 col-span-12">
                    <div className="grid grid-cols-12 gap-6">
                      <div className="xl:col-span-12 md:col-span-12 col-span-12 flex flex-col items-end">
                        <label
                          className="filter-lable flex w-full font-medium 2xl:mb-1 lg:[0.25rem] theme-color min-h-30"
                          htmlFor="default"
                        >
                        </label>
                        {props.isLoadingReject ? (
                          <div className="py-2 w-[350px] btn btn-red text-lg text-white font-bold">
                            <ButtonLoader className="buttonload group relative btn btn-red text-xl text-white focus:outline-none" />
                          </div>
                        ) : (
                          <button
                            className={`py-2 w-[350px] btn btn-red text-lg text-white font-bold`}
                            onClick={() => props.updateCorporateMemberUpdate(props.taskDetails.memberId)}
                          >
                            View Corporate Information
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </SectionWithBorder>
            <button className="absolute top-4 right-4 bg-blue-500 font-semibold text-white py-2 px-4 rounded"
              onClick={() => props.getAllDetailsForGeneratePDF(props.taskDetails.memberId)}>
              Generate PDF
            </button>
          </div>
          <SectionWithBorder title="Application Information">
            <div className="grid grid-cols-12 gap-6  w-full items-center pt-7">

              <div className="xl:col-span-6 md:col-span-6 col-span-12">
                <div className="grid grid-cols-12 gap-6">
                  <div className="xl:col-span-12 md:col-span-12 col-span-12">
                    <label
                      className="filter-lable block font-medium 2xl:mb-1 lg:[0.25rem] theme-color min-h-30"
                      htmlFor="default"
                    >
                      Organisation Name <span className="text-[#C00000]">*</span>
                    </label>
                    <input
                      id="default"
                      className="form-input rounded-none w-full shadow-red py-3 text-lg text-[#757575]"
                      type="text"
                      name="organisationName"
                      value={props.taskDetails.organisationName}
                      onChange={(event) => props.handleChange(event)}
                      validateField={() => props.validateField("organisationName")}
                    />
                    <ValidationText error={props.validState.error.organisationName} />
                  </div>
                </div>
              </div>
              <div className="xl:col-span-6 md:col-span-6 col-span-12">
                <div className="grid grid-cols-12 gap-6">
                  <div className="xl:col-span-12 md:col-span-12 col-span-12">
                    <label
                      className="filter-lable block font-medium 2xl:mb-1 lg:[0.25rem] theme-color min-h-30"
                      htmlFor="default"
                    >
                      Organisation UEN <span className="text-[#C00000]">*</span>
                    </label>
                    <input
                      id="default"
                      className="form-input rounded-none w-full shadow-red py-3 text-lg text-[#757575]"
                      type="text"
                      name="organisationUEN"
                      value={props.taskDetails.organisationUEN}
                      onChange={(event) => props.handleChange(event)}
                      validateField={() => props.validateField("organisationUEN")}
                    />
                    <div>
                      <ValidationText error={props.validState.error.organisationUEN} />
                    </div>
                  </div>
                </div>
              </div>
              <div className="xl:col-span-6 md:col-span-6 col-span-12">
                <div className="grid grid-cols-12 gap-6">
                  <div className="xl:col-span-12 md:col-span-12 col-span-12">
                    <label
                      className="filter-lable block font-medium 2xl:mb-1 lg:[0.25rem] theme-color min-h-30"
                      htmlFor="default"
                    >
                      Paid Up Capital
                    </label>
                    <input
                      id="default"
                      className="form-input rounded-none w-full shadow-red py-3 text-lg text-[#757575]"
                      type="text"
                      name="paidUpCapital"
                      value={props.taskDetails.paidUpCapital ? props.taskDetails.paidUpCapital : ""}
                      onChange={(event) => props.handleChange(event)}
                      validateField={() => props.validateField("paidUpCapital")}
                    />
                  </div>
                  <ValidationText error={props.validState.error.paidUpCapital} />
                </div>
              </div>
              <div className="xl:col-span-6 md:col-span-6 col-span-12">
                <div className="grid grid-cols-12 gap-6">
                  <div className="xl:col-span-12 md:col-span-12 col-span-12">
                    <label
                      className="filter-lable block font-medium 2xl:mb-1 lg:[0.25rem] theme-color min-h-30"
                      htmlFor="default">
                      Incorporation Date <span className="text-[#C00000]">*</span>
                    </label>
                    <input
                      id="default"
                      className="date-pickers form-input rounded-none w-full shadow-red py-3 text-lg"
                      type="date"
                      name="incorporationDate"
                      max={moment(new Date()).format("yyyy-MM-DD")}
                      value={props.taskDetails.incorporationDate ? moment(
                        props.taskDetails.incorporationDate
                      ).format("yyyy-MM-DD") : ""}
                      onChange={(event) => props.handleChange(event)}
                      onBlur={() => props.validateField("incorporationDate")}
                    />
                    <div>
                      <ValidationText error={props.validState.error.incorporationDate} />
                    </div>
                  </div>
                </div>
              </div>

              <div className="xl:col-span-6 md:col-span-6 col-span-12">
                <div className="grid grid-cols-12 gap-6">
                  <div className="xl:col-span-12 md:col-span-12 col-span-12">
                    <label
                      className="filter-lable block font-medium 2xl:mb-1 lg:[0.25rem] theme-color min-h-30"
                      htmlFor="default"
                    > Types of Services <span className="text-[#C00000]">*</span>
                    </label>
                    <MultiSelectDropdown
                      drpIdentity="ServicesType"
                      options={props.serviceType}
                      isMulti
                      closeMenuOnSelect={false}
                      components={{ Option }}
                      hideSelectedOptions={false}
                      onChange={props.handleChangeMultiDropdown}
                      allowSelectAll={true}
                      value={props.servicesTypeData}
                    />
                  </div>
                </div>
              </div>
              <div className="xl:col-span-6 md:col-span-6 col-span-12">
                <div className="grid grid-cols-12 gap-6">
                  <div className="xl:col-span-12 md:col-span-12 col-span-12">
                    <label
                      className="filter-lable block font-medium 2xl:mb-1 lg:[0.25rem] theme-color min-h-30"
                      htmlFor="default"
                    >
                      Types of Industries Served
                      <span className="text-[#C00000]">*</span>
                    </label>
                    <MultiSelectDropdown
                      drpIdentity="IndustriesServed"
                      options={props.industriesServed}
                      isMulti
                      closeMenuOnSelect={false}
                      components={{ Option }}
                      hideSelectedOptions={false}
                      onChange={props.handleChangeMultiDropdown}
                      allowSelectAll={true}
                      value={props.industriesServedData}
                    />
                  </div>
                </div>
              </div>
              <div className="xl:col-span-6 md:col-span-6 col-span-12">
                <div className="grid grid-cols-12 gap-6">
                  <div className="xl:col-span-12 md:col-span-12 col-span-12">
                    <label
                      className="filter-lable block font-medium 2xl:mb-1 lg:[0.25rem] theme-color min-h-30"
                      htmlFor="default"
                    >
                      How does your organisation wish to benefit from joining the association
                      <span className="text-[#C00000]">*</span>
                    </label>
                    {/* <DropdownSelect
                      drpIdentity={"AssociationBenefits"}
                      optionArray={props.associationBenefits}
                      setFilterParameters={props.setFilterParameters}
                      value={props.taskDetails.associationBenefitsId}
                    /> */}
                    <MultiSelectDropdown
                      drpIdentity="AssociationBenefits"
                      options={props.associationBenefits}
                      isMulti
                      closeMenuOnSelect={false}
                      components={{ Option }}
                      hideSelectedOptions={false}
                      onChange={props.handleChangeMultiDropdown}
                      allowSelectAll={true}
                      value={props.associationBenefitsData}
                    />
                  </div>
                </div>
              </div>
            </div>
          </SectionWithBorder>

          {/* Reinstatement Portion */}
          {props.taskDetails.transactionTypeId === 5 ?
            <>
              <SectionWithBorder title="Reinstatement Information">
                <div className="grid grid-cols-12 gap-6 w-full items-center pt-7">
                  <div className="xl:col-span-4 md:col-span-6 col-span-12">
                    <div className="grid grid-cols-12 gap-6">
                      <div className="xl:col-span-12 md:col-span-12 col-span-12">
                        <label
                          className="filter-lable block font-medium 2xl:mb-1 lg:[0.25rem] theme-color min-h-30"
                          htmlFor="default">
                          Request Date <span className="text-[#C00000]">*</span>
                        </label>
                        <input
                          id="default"
                          className="date-pickers form-input rounded-none w-full shadow-red py-3 text-lg"
                          type="date"
                          name="reinstatementRequestDate"
                          max={moment(new Date()).format("yyyy-MM-DD")}
                          value={props.taskDetails.reinstatementRequestDate ? moment(
                            props.taskDetails.reinstatementRequestDate
                          ).format("yyyy-MM-DD") : ""}
                          onChange={(event) => props.handleChange(event)}
                          onBlur={() => props.validateField("incorporationDate")}
                        />
                        <div>
                          <ValidationText error={props.validState.error.incorporationDate} />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="xl:col-span-4 md:col-span-6 col-span-12">
                    <div className="grid grid-cols-12 gap-6">
                      <div className="xl:col-span-12 md:col-span-12 col-span-12">
                        <label
                          className="filter-lable block font-medium 2xl:mb-1 lg:[0.25rem] theme-color min-h-30"
                          htmlFor="default">
                          Start Date <span className="text-[#C00000]">*</span>
                        </label>
                        <input
                          id="default"
                          className="date-pickers form-input rounded-none w-full shadow-red py-3 text-lg"
                          type="date"
                          name="reinstatementStartDate"
                          max={moment(new Date()).format("yyyy-MM-DD")}
                          value={props.taskDetails.reinstatementStartDate ? moment(
                            props.taskDetails.reinstatementStartDate
                          ).format("yyyy-MM-DD") : ""}
                          onChange={(event) => props.handleChange(event)}
                          onBlur={() => props.validateField("incorporationDate")}
                        />
                        <div>
                          <ValidationText error={props.validState.error.incorporationDate} />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="xl:col-span-4 md:col-span-6 col-span-12">
                    <div className="grid grid-cols-12 gap-6">
                      <div className="xl:col-span-12 md:col-span-12 col-span-12">
                        <label
                          className="filter-lable block font-medium 2xl:mb-1 lg:[0.25rem] theme-color min-h-30"
                          htmlFor="default">
                          End Date <span className="text-[#C00000]">*</span>
                        </label>
                        <input
                          id="default"
                          className="date-pickers form-input rounded-none w-full shadow-red py-3 text-lg"
                          type="date"
                          name="reinstatementEndDate"
                          max={moment(new Date()).format("yyyy-MM-DD")}
                          value={props.taskDetails.reinstatementEndDate ? moment(
                            props.taskDetails.reinstatementEndDate
                          ).format("yyyy-MM-DD") : ""}
                          onChange={(event) => props.handleChange(event)}
                          onBlur={() => props.validateField("incorporationDate")}
                        />
                        <div>
                          <ValidationText error={props.validState.error.incorporationDate} />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="grid grid-cols-12 gap-6 w-full items-center pt-7">
                    <div className="xl:col-span-12 md:col-span-12 col-span-12">
                      <label
                        className="filter-lable block font-medium 2xl:mb-1 lg:[0.25rem] theme-color min-h-30"
                        htmlFor="default"
                      >
                        Reinstatement Amount <span className="text-[#C00000]">*</span>
                      </label>
                      <div className="relative">
                        <span className="absolute inset-y-0 left-0 pl-3 flex items-center text-[#757575] text-xl pointer-events-none">
                          $
                        </span>
                        <input
                          id="default"
                          className="form-input rounded-none w-full shadow-red py-3 pl-8 text-2xl text-[#757575]"
                          type="text"
                          name="reinstatementAmount"
                          value={
                            props.taskDetails.reinstatementAmount
                              ? parseFloat(props.taskDetails.reinstatementAmount).toFixed(2)
                              : "0.00"
                          }
                          onChange={(event) => props.handleChange(event)}
                          validateField={() => props.validateField("organisationName")}
                        />
                        <ValidationText error={props.validState.error.organisationName} />
                      </div>
                    </div>
                  </div>

                </div>
              </SectionWithBorder>

              <SectionWithBorder title="Council Approval">
                <div className="">
                  <div className="grid grid-cols-12 gap-6 w-full items-center pt-3 pb-7 mt-5">
                    <div className="xl:col-span-6 md:col-span-6 col-span-12">
                      <label
                        className="filter-lable block font-medium 2xl:mb-1 lg:[0.25rem] theme-color min-h-30"
                        htmlFor="default"
                      >
                        Subscription Start Date{" "}
                      </label>
                      <input
                        id="default"
                        className="date-pickers form-input rounded-none w-full bg-gray-200 shadow-red py-3 text-lg"
                        type="date"
                        name="subscriptionStartDate"
                        disabled={true}
                        //max={moment(new Date()).format("yyyy-MM-DD")}
                        value={props.taskDetails.reinstatementStartDate ? moment(props.taskDetails.reinstatementStartDate).format("yyyy-MM-DD") : moment(new Date()).format("yyyy-MM-DD")}
                        onChange={(event) => props.handleChange(event)}
                      //onBlur={() => props.validateField("incorporationDate")}
                      />
                      <div>
                        {" "}
                        <ValidationText
                          error={props.validState.error.incorporationDate}
                        />{" "}
                      </div>
                      <ValidationText
                        error={
                          props.validState.error
                            .incorporationDate
                        }
                      />
                    </div>

                    <div className="xl:col-span-6 md:col-span-6 col-span-12">
                      <label
                        className="filter-lable block font-medium 2xl:mb-1 lg:[0.25rem] theme-color min-h-30"
                        htmlFor="default"
                      >
                        Committee Approval Date{" "}
                        <span className="text-[#C00000]">*</span>
                      </label>
                      <input
                        id="default"
                        className="date-pickers form-input rounded-none w-full shadow-red py-3 text-lg"
                        type="date"
                        name="committeeApprovalDate"
                        //max={moment(new Date()).format("yyyy-MM-DD")}
                        value={props.taskDetails.committeeApprovalDate ? moment(props.taskDetails.committeeApprovalDate).format("yyyy-MM-DD") : moment(new Date()).format("yyyy-MM-DD")}
                        onChange={(event) => props.handleChange(event)}
                      />
                      <div>
                      </div>
                    </div>
                  </div>
                </div>
              </SectionWithBorder>
            </>
            :
            <>
              {/* Normal Membership/Renewal Portion */}
              {
                props.taskDetails.isAppliedForRenewal === false ?
                  <>   <hr></hr>
                    <div className="grid grid-cols-12 gap-6  w-full items-center pt-4  pb-4">
                      {props.taskDetails.contactInformationDetail && props.taskDetails.contactInformationDetail.map((data, index) => (
                        <div key={index} className="xl:col-span-6 md:col-span-6 col-span-12 gap-6 row ">
                          {data.contactRoleId === 1 ? (
                            <div className="col-xl-6 col-md-6 col-12">
                              <h2 className="text-xl font-semibold pb-4">CEO Details</h2>
                              <input
                                id="default"
                                className="form-input rounded-none w-full shadow-red py-3 text-lg text-[#757575]"
                                type="text"
                                name="contactRoleId"
                                value={1}
                                style={{ display: 'none' }}
                                onChange={(e) => props.handleChangecontactinformation(index, 'contactRoleId', 1)}
                              />
                              <div className="xl:col-span-6 md:col-span-6 col-span-6 pb-[1.5rem]">
                                <div className="grid grid-cols-12 gap-6">
                                  <div className="xl:col-span-12 md:col-span-6 col-span-12">
                                    <label
                                      className="filter-lable block font-medium 2xl:mb-1 lg:[0.25rem] theme-color min-h-30"
                                      htmlFor="default"
                                    >
                                      CEO First Name <span className="text-[#C00000]">*</span>
                                    </label>
                                    <input
                                      id="default"
                                      className="form-input rounded-none w-full shadow-red py-3 text-lg text-[#757575]"
                                      type="text"
                                      name="firstName"
                                      value={data.firstName || ''}
                                      onChange={(e) => props.handleChangecontactinformation(index, 'firstName', e.target.value)}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div>
                              </div>
                              <div className="xl:col-span-6 md:col-span-6 col-span-12 pb-[1.5rem]">
                                <div className="grid grid-cols-12 gap-6">
                                  <div className="xl:col-span-12 md:col-span-6 col-span-12">
                                    <label
                                      className="filter-lable block font-medium 2xl:mb-1 lg:[0.25rem] theme-color min-h-30"
                                      htmlFor="default"
                                    >
                                      CEO Last Name <span className="text-[#C00000]">*</span>
                                    </label>
                                    <input
                                      id="default"
                                      className="form-input rounded-none w-full shadow-red py-3 text-lg text-[#757575]"
                                      type="text"
                                      name="lastName"
                                      value={data.lastName || ''}
                                      onChange={(e) => props.handleChangecontactinformation(index, 'lastName', e.target.value)}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="xl:col-span-6 md:col-span-6 col-span-12 pb-[1.5rem]">
                                <div className="grid grid-cols-12 gap-6">
                                  <div className="xl:col-span-12 md:col-span-6 col-span-12">
                                    <label
                                      className="filter-lable block font-medium 2xl:mb-1 lg:[0.25rem] theme-color min-h-30"
                                      htmlFor="default"
                                    >
                                      CEO Email Address <span className="text-[#C00000]">*</span>
                                    </label>
                                    <input
                                      id="default"
                                      className="form-input rounded-none w-full shadow-red py-3 text-lg text-[#757575]"
                                      type="text"
                                      name="emailAddress"
                                      value={data.emailAddress || ''}
                                      onChange={(e) => props.handleChangecontactinformation(index, 'emailAddress', e.target.value)}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="xl:col-span-12 md:col-span-12 col-span-12 pb-[1.5rem]">
                                <div className="grid grid-cols-12 gap-6">
                                  <div className="xl:col-span-12 md:col-span-6 col-span-12">
                                    <label
                                      className="filter-lable block font-medium 2xl:mb-1 lg:[0.25rem] theme-color min-h-30"
                                      htmlFor="default"
                                    >
                                      Job Position <span className="text-[#C00000]">*</span>
                                    </label>
                                    <input
                                      id="default"
                                      className="form-input rounded-none w-full shadow-red py-3 text-lg text-[#757575]"
                                      type="text"
                                      name="designation"
                                      value={data.designation || ''}
                                      onChange={(e) => props.handleChangecontactinformation(index, 'designation', e.target.value)}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          ) :
                            data.contactRoleId === 2 ? (
                              <div className="col-xl-6 col-md-6 col-12">
                                <h2 className="text-xl font-semibold pb-4"> First Representative Details</h2>
                                <input
                                  id="default"
                                  className="form-input rounded-none w-full shadow-red py-3 text-lg text-[#757575]"
                                  type="text"
                                  name="contactRoleId"
                                  value={2}
                                  style={{ display: 'none' }}
                                  onChange={(e) => props.handleChangecontactinformation(index, 'contactRoleId', 2)}
                                />
                                <div className="xl:col-span-6 md:col-span-6 col-span-12 pb-[1.5rem]">
                                  <div className="grid grid-cols-12 gap-6">
                                    <div className="xl:col-span-12 md:col-span-12 col-span-12">
                                      <label
                                        className="filter-lable block font-medium 2xl:mb-1 lg:[0.25rem] theme-color min-h-30"
                                        htmlFor="default"
                                      >
                                        First Representative First Name<span className="text-[#C00000]">*</span>
                                      </label>

                                      <input
                                        id="default"
                                        className="form-input rounded-none w-full shadow-red py-3 text-lg text-[#757575]"
                                        type="text"
                                        name="firstName"
                                        value={data.firstName || ''}
                                        onChange={(e) => props.handleChangecontactinformation(index, 'firstName', e.target.value)}
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="xl:col-span-6 md:col-span-6 col-span-12  pb-[1.5rem]">
                                  <div className="grid grid-cols-12 gap-6">
                                    <div className="xl:col-span-12 md:col-span-12 col-span-12  ">
                                      <label
                                        className="filter-lable block font-medium 2xl:mb-1 lg:[0.25rem] theme-color min-h-30"
                                        htmlFor="default"
                                      >
                                        First Representative Last Name<span className="text-[#C00000]">*</span>
                                      </label>
                                      <input
                                        id="default"
                                        className="form-input rounded-none w-full shadow-red py-3 text-lg text-[#757575]"
                                        type="text"
                                        name="lastName"
                                        value={data.lastName || ''}
                                        onChange={(e) => props.handleChangecontactinformation(index, 'lastName', e.target.value)}
                                      />

                                    </div>
                                  </div>
                                </div>
                                <div className="xl:col-span-6 md:col-span-6 col-span-12  pb-[1.5rem]">
                                  <div className="grid grid-cols-12 gap-6">
                                    <div className="xl:col-span-12 md:col-span-12 col-span-12">
                                      <label
                                        className="filter-lable block font-medium 2xl:mb-1 lg:[0.25rem] theme-color min-h-30"
                                        htmlFor="default"
                                      >
                                        First Representative Email Address <span className="text-[#C00000]">*</span>
                                      </label>
                                      <input
                                        id="default"
                                        className="form-input rounded-none w-full shadow-red py-3 text-lg text-[#757575]"
                                        type="text"
                                        name="emailAddress"
                                        value={data.emailAddress || ''}
                                        onChange={(e) => props.handleChangecontactinformation(index, 'emailAddress', e.target.value)}
                                      />

                                    </div>
                                  </div>
                                </div>
                                <div className="xl:col-span-12 md:col-span-12 col-span-12  pb-[1.5rem]">
                                  <div className="grid grid-cols-12 gap-6">
                                    <div className="xl:col-span-12 md:col-span-6 col-span-12">
                                      <label
                                        className="filter-lable block font-medium 2xl:mb-1 lg:[0.25rem] theme-color min-h-30"
                                        htmlFor="default"
                                      >
                                        {" "}
                                        Job Position{" "}
                                        <span className="text-[#C00000]">*</span>{" "}
                                      </label>
                                      <input
                                        id="default"
                                        className="form-input rounded-none w-full shadow-red py-3 text-lg text-[#757575]"
                                        type="text"
                                        name="designation"
                                        value={data.designation || ''}
                                        onChange={(e) => props.handleChangecontactinformation(index, 'designation', e.target.value)}
                                      />
                                    </div>
                                  </div>
                                </div>
                                {/* Add other representative details here */}
                              </div>
                            ) :
                              data.contactRoleId === 3 ? (
                                <>
                                  <h2 className="text-xl font-semibold mb-4">Second Representative Details</h2>
                                  <div className="xl:col-span-6 md:col-span-6 col-span-12  pb-[1.5rem]">
                                    <div className="grid grid-cols-12 gap-6">
                                      <div className="xl:col-span-12 md:col-span-12 col-span-12">
                                        <label
                                          className="filter-lable block font-medium 2xl:mb-1 lg:[0.25rem] theme-color min-h-30"
                                          htmlFor="default"
                                        >
                                          Second Representative First Name<span className="text-[#C00000]">*</span>
                                        </label>

                                        <input
                                          id="default"
                                          className="form-input rounded-none w-full shadow-red py-3 text-lg text-[#757575]"
                                          type="text"
                                          name="firstName"
                                          value={data.firstName || ''}
                                          onChange={(e) => props.handleChangecontactinformation(index, 'firstName', e.target.value)}
                                        />

                                      </div>
                                    </div>
                                  </div>
                                  <div className="xl:col-span-6 md:col-span-6 col-span-12 pb-[1.5rem]">
                                    <div className="grid grid-cols-12 gap-6">
                                      <div className="xl:col-span-12 md:col-span-12 col-span-12">
                                        <label
                                          className="filter-lable block font-medium 2xl:mb-1 lg:[0.25rem] theme-color min-h-30"
                                          htmlFor="default"
                                        >
                                          Second Representative Last Name<span className="text-[#C00000]">*</span>
                                        </label>

                                        <input
                                          id="default"
                                          className="form-input rounded-none w-full shadow-red py-3 text-lg text-[#757575]"
                                          type="text"
                                          name="lastName"
                                          value={data.lastName || ''}
                                          onChange={(e) => props.handleChangecontactinformation(index, 'lastName', e.target.value)}
                                        />

                                      </div>
                                    </div>
                                  </div>
                                  <div className="xl:col-span-6 md:col-span-6 col-span-12 pb-[1.5rem]">
                                    <div className="grid grid-cols-12 gap-6">
                                      <div className="xl:col-span-12 md:col-span-12 col-span-12">
                                        <label
                                          className="filter-lable block font-medium 2xl:mb-1 lg:[0.25rem] theme-color min-h-30"
                                          htmlFor="default"
                                        >
                                          Second Representative Email Address <span className="text-[#C00000]">*</span>
                                        </label>

                                        <input
                                          id="default"
                                          className="form-input rounded-none w-full shadow-red py-3 text-lg text-[#757575]"
                                          type="text"
                                          name="emailAddress"
                                          value={data.emailAddress || ''}
                                          onChange={(e) => props.handleChangecontactinformation(index, 'emailAddress', e.target.value)}

                                        />

                                      </div>
                                    </div>
                                  </div>
                                  <div className="xl:col-span-12 md:col-span-12 col-span-12 pb-[1.5rem]">
                                    <div className="grid grid-cols-12 gap-6">
                                      <div className="xl:col-span-12 md:col-span-6 col-span-12">
                                        <label
                                          className="filter-lable block font-medium 2xl:mb-1 lg:[0.25rem] theme-color min-h-30"
                                          htmlFor="default"
                                        >
                                          {" "}
                                          Job Position{" "}
                                          <span className="text-[#C00000]">*</span>{" "}
                                        </label>
                                        <input
                                          id="default"
                                          className="form-input rounded-none w-full shadow-red py-3 text-lg text-[#757575]"
                                          type="text"
                                          name="designation"
                                          value={data.designation || ''}
                                          onChange={(e) => props.handleChangecontactinformation(index, 'designation', e.target.value)}
                                        />
                                        {/* <ValidationText
                        error={
                          props.validState.error.membershipTypeId
                        }
                      /> */}
                                      </div>

                                    </div>
                                  </div>
                                  {/* Add other representative details here */}
                                </>
                              )
                                : data.contactRoleId === 4 ? (
                                  <>
                                    <h2 className="text-xl font-semibold mb-4">Finance Department Details</h2>
                                    <div className="xl:col-span-6 md:col-span-6 col-span-12 pb-[1.5rem]">
                                      <div className="grid grid-cols-12 gap-6">
                                        <div className="xl:col-span-12 md:col-span-12 col-span-12">
                                          <label
                                            className="filter-lable block font-medium 2xl:mb-1 lg:[0.25rem] theme-color min-h-30"
                                            htmlFor="default"
                                          >
                                            Finance First Name<span className="text-[#C00000]">*</span>
                                          </label>
                                          <div className=" ">
                                            <input
                                              id="default"
                                              className="form-input rounded-none w-full shadow-red py-3 text-lg text-[#757575]"
                                              type="text"
                                              name="firstName"
                                              value={data.firstName || ''}
                                              onChange={(e) => props.handleChangecontactinformation(index, 'firstName', e.target.value)}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="xl:col-span-6 md:col-span-6 col-span-12 pb-[1.5rem]">
                                      <div className="grid grid-cols-12 gap-6">
                                        <div className="xl:col-span-12 md:col-span-12 col-span-12">
                                          <label
                                            className="filter-lable block font-medium 2xl:mb-1 lg:[0.25rem] theme-color min-h-30"
                                            htmlFor="default"
                                          >
                                            Finance Last Name<span className="text-[#C00000]">*</span>
                                          </label>
                                          <div className=" ">
                                            <input
                                              id="default"
                                              className="form-input rounded-none w-full shadow-red py-3 text-lg text-[#757575]"
                                              type="text"
                                              name="lastName"
                                              value={data.lastName || ''}
                                              onChange={(e) => props.handleChangecontactinformation(index, 'lastName', e.target.value)}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="xl:col-span-6 md:col-span-6 col-span-12 pb-[1.5rem]">
                                      <div className="grid grid-cols-12 gap-6">
                                        <div className="xl:col-span-12 md:col-span-12 col-span-12">
                                          <label
                                            className="filter-lable block font-medium 2xl:mb-1 lg:[0.25rem] theme-color min-h-30"
                                            htmlFor="default"
                                          >
                                            Finance Email Address <span className="text-[#C00000]">*</span>
                                          </label>
                                          <div className=" ">
                                            <input
                                              id="default"
                                              className="form-input rounded-none w-full shadow-red py-3 text-lg text-[#757575]"
                                              type="text"
                                              name="emailAddress"
                                              value={data.emailAddress || ''}
                                              onChange={(e) => props.handleChangecontactinformation(index, 'emailAddress', e.target.value)}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="xl:col-span-12 md:col-span-12 col-span-12 pb-[1.5rem]">
                                      <div className="grid grid-cols-12 gap-6">
                                        <div className="xl:col-span-12 md:col-span-6 col-span-12">
                                          <label
                                            className="filter-lable block font-medium 2xl:mb-1 lg:[0.25rem] theme-color min-h-30"
                                            htmlFor="default"
                                          >
                                            {" "}
                                            Job Position{" "}
                                            <span className="text-[#C00000]">*</span>{" "}
                                          </label>
                                          <input
                                            id="default"
                                            className="form-input rounded-none w-full shadow-red py-3 text-lg text-[#757575]"
                                            type="text"
                                            name="designation"
                                            value={data.designation || ''}
                                            onChange={(e) => props.handleChangecontactinformation(index, 'designation', e.target.value)}
                                          />
                                          {/* <ValidationText
                        error={
                          props.validState.error.membershipTypeId
                        }
                      /> */}
                                        </div>

                                      </div>
                                    </div>
                                    {/* Add other representative details here */}
                                  </>
                                ) :
                                  data.contactRoleId === 5 ? (
                                    <>
                                      <h2 className="text-xl font-semibold mb-4">Human Resource Department Details</h2>
                                      <div className="xl:col-span-6 md:col-span-6 col-span-12 pb-[1.5rem]">
                                        <div className="grid grid-cols-12 gap-6">
                                          <div className="xl:col-span-12 md:col-span-12 col-span-12">
                                            <label
                                              className="filter-lable block font-medium 2xl:mb-1 lg:[0.25rem] theme-color min-h-30"
                                              htmlFor="default"
                                            >
                                              First Name<span className="text-[#C00000]">*</span>
                                            </label>
                                            <div className=" ">
                                              <input
                                                id="default"
                                                className="form-input rounded-none w-full shadow-red py-3 text-lg text-[#757575]"
                                                type="text"
                                                name="firstName"
                                                value={data.firstName || ''}
                                                onChange={(e) => props.handleChangecontactinformation(index, 'firstName', e.target.value)}
                                              />
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="xl:col-span-6 md:col-span-6 col-span-12 pb-[1.5rem]">
                                        <div className="grid grid-cols-12 gap-6">
                                          <div className="xl:col-span-12 md:col-span-12 col-span-12">
                                            <label
                                              className="filter-lable block font-medium 2xl:mb-1 lg:[0.25rem] theme-color min-h-30"
                                              htmlFor="default"
                                            >
                                              Last Name<span className="text-[#C00000]">*</span>
                                            </label>
                                            <div className=" ">
                                              <input
                                                id="default"
                                                className="form-input rounded-none w-full shadow-red py-3 text-lg text-[#757575]"
                                                type="text"
                                                name="lastName"
                                                value={data.lastName || ''}
                                                onChange={(e) => props.handleChangecontactinformation(index, 'lastName', e.target.value)}
                                              />
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="xl:col-span-6 md:col-span-6 col-span-12 pb-[1.5rem]">
                                        <div className="grid grid-cols-12 gap-6">
                                          <div className="xl:col-span-12 md:col-span-12 col-span-12">
                                            <label
                                              className="filter-lable block font-medium 2xl:mb-1 lg:[0.25rem] theme-color min-h-30"
                                              htmlFor="default"
                                            >
                                              Email Address <span className="text-[#C00000]">*</span>
                                            </label>
                                            <div className=" ">
                                              <input
                                                id="default"
                                                className="form-input rounded-none w-full shadow-red py-3 text-lg text-[#757575]"
                                                type="text"
                                                name="emailAddress"
                                                value={data.emailAddress || ''}
                                                onChange={(e) => props.handleChangecontactinformation(index, 'emailAddress', e.target.value)}
                                              />
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="xl:col-span-12 md:col-span-12 col-span-12 pb-[1.5rem]">
                                        <div className="grid grid-cols-12 gap-6">
                                          <div className="xl:col-span-12 md:col-span-6 col-span-12">
                                            <label
                                              className="filter-lable block font-medium 2xl:mb-1 lg:[0.25rem] theme-color min-h-30"
                                              htmlFor="default"
                                            >
                                              {" "}
                                              Job Position{" "}
                                              <span className="text-[#C00000]">*</span>{" "}
                                            </label>
                                            <input
                                              id="default"
                                              className="form-input rounded-none w-full shadow-red py-3 text-lg text-[#757575]"
                                              type="text"
                                              name="designation"
                                              value={data.designation || ''}
                                              onChange={(e) => props.handleChangecontactinformation(index, 'designation', e.target.value)}
                                            />
                                            {/* <ValidationText
                        error={
                          props.validState.error.membershipTypeId
                        }
                      /> */}
                                          </div>

                                        </div>
                                      </div>
                                      {/* Add other representative details here */}
                                    </>
                                  )
                                    : data.contactRoleId === 6 ? (
                                      <>
                                        <h2 className="text-xl font-semibold mb-4">Women Executive Department Details</h2>
                                        <div className="xl:col-span-6 md:col-span-6 col-span-12 pb-[1.5rem]">
                                          <div className="grid grid-cols-12 gap-6">
                                            <div className="xl:col-span-12 md:col-span-12 col-span-12">
                                              <label
                                                className="filter-lable block font-medium 2xl:mb-1 lg:[0.25rem] theme-color min-h-30"
                                                htmlFor="default"
                                              >
                                                First Name<span className="text-[#C00000]">*</span>
                                              </label>
                                              <div className=" ">
                                                <input
                                                  id="default"
                                                  className="form-input rounded-none w-full shadow-red py-3 text-lg text-[#757575]"
                                                  type="text"
                                                  name="firstName"
                                                  value={data.firstName || ''}
                                                  onChange={(e) => props.handleChangecontactinformation(index, 'firstName', e.target.value)}
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="xl:col-span-6 md:col-span-6 col-span-12 pb-[1.5rem]">
                                          <div className="grid grid-cols-12 gap-6">
                                            <div className="xl:col-span-12 md:col-span-12 col-span-12">
                                              <label
                                                className="filter-lable block font-medium 2xl:mb-1 lg:[0.25rem] theme-color min-h-30"
                                                htmlFor="default"
                                              >
                                                Last Name<span className="text-[#C00000]">*</span>
                                              </label>
                                              <div className=" ">
                                                <input
                                                  id="default"
                                                  className="form-input rounded-none w-full shadow-red py-3 text-lg text-[#757575]"
                                                  type="text"
                                                  name="lastName"
                                                  value={data.lastName || ''}
                                                  onChange={(e) => props.handleChangecontactinformation(index, 'lastName', e.target.value)}
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="xl:col-span-6 md:col-span-6 col-span-12 pb-[1.5rem]">
                                          <div className="grid grid-cols-12 gap-6">
                                            <div className="xl:col-span-12 md:col-span-12 col-span-12">
                                              <label
                                                className="filter-lable block font-medium 2xl:mb-1 lg:[0.25rem] theme-color min-h-30"
                                                htmlFor="default"
                                              >
                                                Email Address <span className="text-[#C00000]">*</span>
                                              </label>
                                              <div className=" ">
                                                <input
                                                  id="default"
                                                  className="form-input rounded-none w-full shadow-red py-3 text-lg text-[#757575]"
                                                  type="text"
                                                  name="emailAddress"
                                                  value={data.emailAddress || ''}
                                                  onChange={(e) => props.handleChangecontactinformation(index, 'emailAddress', e.target.value)}
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="xl:col-span-12 md:col-span-12 col-span-12 pb-[1.5rem]">
                                          <div className="grid grid-cols-12 gap-6">
                                            <div className="xl:col-span-12 md:col-span-6 col-span-12">
                                              <label
                                                className="filter-lable block font-medium 2xl:mb-1 lg:[0.25rem] theme-color min-h-30"
                                                htmlFor="default"
                                              >
                                                {" "}
                                                Job Position{" "}
                                                <span className="text-[#C00000]">*</span>{" "}
                                              </label>
                                              <input
                                                id="default"
                                                className="form-input rounded-none w-full shadow-red py-3 text-lg text-[#757575]"
                                                type="text"
                                                name="designation"
                                                value={data.designation || ''}
                                                onChange={(e) => props.handleChangecontactinformation(index, 'designation', e.target.value)}
                                              />
                                              {/* <ValidationText
                        error={
                          props.validState.error.membershipTypeId
                        }
                      /> */}
                                            </div>

                                          </div>
                                        </div>
                                        {/* Add other representative details here */}
                                      </>
                                    ) : data.contactRoleId === 7 ? (
                                      <>
                                        <h2 className="text-xl font-semibold mb-4">Youth Executive Department Details</h2>
                                        <div className="xl:col-span-6 md:col-span-6 col-span-12 pb-[1.5rem]">
                                          <div className="grid grid-cols-12 gap-6">
                                            <div className="xl:col-span-12 md:col-span-12 col-span-12">
                                              <label
                                                className="filter-lable block font-medium 2xl:mb-1 lg:[0.25rem] theme-color min-h-30"
                                                htmlFor="default"
                                              >
                                                First Name<span className="text-[#C00000]">*</span>
                                              </label>
                                              <div className=" ">
                                                <input
                                                  id="default"
                                                  className="form-input rounded-none w-full shadow-red py-3 text-lg text-[#757575]"
                                                  type="text"
                                                  name="firstName"
                                                  value={data.firstName || ''}
                                                  onChange={(e) => props.handleChangecontactinformation(index, 'firstName', e.target.value)}
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="xl:col-span-6 md:col-span-6 col-span-12 pb-[1.5rem]">
                                          <div className="grid grid-cols-12 gap-6">
                                            <div className="xl:col-span-12 md:col-span-12 col-span-12">
                                              <label
                                                className="filter-lable block font-medium 2xl:mb-1 lg:[0.25rem] theme-color min-h-30"
                                                htmlFor="default"
                                              >
                                                Last Name<span className="text-[#C00000]">*</span>
                                              </label>
                                              <div className=" ">
                                                <input
                                                  id="default"
                                                  className="form-input rounded-none w-full shadow-red py-3 text-lg text-[#757575]"
                                                  type="text"
                                                  name="lastName"
                                                  value={data.lastName || ''}
                                                  onChange={(e) => props.handleChangecontactinformation(index, 'lastName', e.target.value)}
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="xl:col-span-6 md:col-span-6 col-span-12 pb-[1.5rem]">
                                          <div className="grid grid-cols-12 gap-6">
                                            <div className="xl:col-span-12 md:col-span-12 col-span-12">
                                              <label
                                                className="filter-lable block font-medium 2xl:mb-1 lg:[0.25rem] theme-color min-h-30"
                                                htmlFor="default"
                                              >
                                                Email Address <span className="text-[#C00000]">*</span>
                                              </label>
                                              <div className=" ">
                                                <input
                                                  id="default"
                                                  className="form-input rounded-none w-full shadow-red py-3 text-lg text-[#757575]"
                                                  type="text"
                                                  name="emailAddress"
                                                  value={data.emailAddress || ''}
                                                  onChange={(e) => props.handleChangecontactinformation(index, 'emailAddress', e.target.value)}
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="xl:col-span-12 md:col-span-12 col-span-12 pb-[1.5rem]">
                                          <div className="grid grid-cols-12 gap-6">
                                            <div className="xl:col-span-12 md:col-span-6 col-span-12">
                                              <label
                                                className="filter-lable block font-medium 2xl:mb-1 lg:[0.25rem] theme-color min-h-30"
                                                htmlFor="default"
                                              >
                                                {" "}
                                                Job Position{" "}
                                                <span className="text-[#C00000]">*</span>{" "}
                                              </label>
                                              <input
                                                id="default"
                                                className="form-input rounded-none w-full shadow-red py-3 text-lg text-[#757575]"
                                                type="text"
                                                name="designation"
                                                value={data.designation || ''}
                                                onChange={(e) => props.handleChangecontactinformation(index, 'designation', e.target.value)}
                                              />
                                              {/* <ValidationText
                        error={
                          props.validState.error.membershipTypeId
                        }
                      /> */}
                                            </div>

                                          </div>
                                        </div>
                                        {/* Add other representative details here */}
                                      </>
                                    )
                                      : null}
                        </div>
                      ))}
                    </div>

                    <SectionWithBorder title="Endorsers">
                      <div className="">
                        <div className="grid grid-cols-12 gap-6  w-full items-center pt-7 ">
                          <div className="xl:col-span-12 md:col-span-12 col-span-12">
                            <div className="grid grid-cols-12 gap-6">
                              <div className="xl:col-span-5 md:col-span-6 col-span-12">
                                <label
                                  className="filter-lable block font-medium 2xl:mb-1 lg:[0.25rem] theme-color min-h-30"
                                  htmlFor="default"
                                >
                                  {" "}
                                  Endorser Company 1 {" "}
                                  <span className="text-[#C00000]">*</span>{" "}
                                </label>

                                  <SingleSelectWithSearch
                                      drpIdentity={"OrdinaryEndorsers"}
                                      optionArray={props.filtered}
                                      handleChange={(e, identity) => props.handleChangeForSelector(e, identity)}
                                      searchText={props.taskDetails.endorsersId1Name}
                                      setFilterParameters={(id, drpIdentity, name) => props.setFilterParametersForSelector(id, drpIdentity, name)}
                                      value={props.taskDetails.endorsersId1}
                                      disabled={props.taskDetails.endorsementSatusForEndorser1 === 'Approved' ? true : false}
                                    
                                  />


                              </div>


                              <div className="xl:col-span-5 md:col-span-6 col-span-12">
                                <label
                                  className="filter-lable block font-medium 2xl:mb-1 lg:[0.25rem] theme-color min-h-30"
                                  htmlFor="default"
                                >
                                  {" "}
                                  Representative{" "}
                                  <span className="text-[#C00000]">*</span>{" "}
                                </label>
                                <DropdownSelect
                                  drpIdentity={"OrdinaryRepresentatives"}
                                  optionArray={props.ordinaryRepresentative}
                                  setFilterParameters={props.setFilterParameters}
                                  value={props.taskDetails.representativeId1 ? props.taskDetails.representativeId1 : 0}
                                  disabled={props.taskDetails.endorsementSatusForEndorser1 === 'Approved' ? true : false}
                                />
                              </div>

                              <div className="xl:col-span-2 md:col-span-6 col-span-12 mt-3">
                                <div className="col-span-12 flex justify-end">
                                  <p>Status:{props.taskDetails.endorsementSatusForEndorser1}</p>
                                </div>
                                <div className="col-span-12 flex justify-end">
                                  {props.isLoadingSendMail1 ? (
                                    <div className="relative w-[300px]">
                                      <button className={"text-xl font-bold text-white btn-blue-border w-full bg-blue-500 p-4 rounded-md hover:bg-blue-600 focus:outline-none"}>
                                        <span className="absolute left-[15px] top-1/2 transform -translate-y-1/2">
                                          <i className="fa fa-spinner fa-spin text-white text-[17px]"></i>
                                        </span>
                                        <span className="ml-8">
                                          Loading
                                        </span>
                                      </button>
                                    </div>
                                  ) : (
                                    <button
                                      className={`text-xl font-bold text-white btn-blue-border w-[300px] bg-blue-500 p-4 rounded-md hover:bg-blue-600 focus:outline-none
                            ${props.taskDetails.endorsementSatusForEndorser1 === 'Approved' ? 'cursor-not-allowed bg-[#e9e9ea]' : ''}`}
                                      onClick={() => props.getEndorsersEmailDetailById(true, 1)}
                                      disabled={props.taskDetails.endorsementSatusForEndorser1 === 'Approved' ? true : false}
                                    >
                                      Send Mail
                                    </button>
                                  )}
                                </div>
                              </div>


                              <div className="xl:col-span-5 md:col-span-6 col-span-12">
                                <label
                                  className="filter-lable block font-medium 2xl:mb-1 lg:[0.25rem] theme-color min-h-30"
                                  htmlFor="default"
                                >
                                  {" "}
                                  Endorser Company 2{" "}
                                  <span className="text-[#C00000]">*</span>{" "}
                                </label>
                             
                                  <SingleSelectWithSearch 
                                      drpIdentity={"AllEndorsers"}
                                      optionArray={props.allEndorsers}
                                      handleChange={(e, identity) => props.handleChangeForSelector(e, identity)}
                                      searchText={props.taskDetails.endorsersId2Name}
                                      setFilterParameters={(id, drpIdentity, name) => props.setFilterParametersForSelector(id, drpIdentity, name)}
                                      value={props.taskDetails.endorsersId2}
                                      disabled={props.taskDetails.endorsementSatusForEndorser2 === 'Approved' ? true : false}
                                    
                                  />

                                {/* <ValidationText
                        error={
                          props.validState.error.transactionTypeId
                        }
                      /> */}
                              </div>
                              <div className="xl:col-span-5 md:col-span-6 col-span-12">
                                <label
                                  className="filter-lable block font-medium 2xl:mb-1 lg:[0.25rem] theme-color min-h-30"
                                  htmlFor="default"
                                >
                                  {" "}
                                  Representative 2{" "}
                                  <span className="text-[#C00000]">*</span>{" "}
                                </label>
                                <DropdownSelect
                                  drpIdentity={"AllRepresentatives"}
                                  optionArray={props.allRepresentative}
                                  setFilterParameters={props.setFilterParameters}
                                  value={props.taskDetails.representativeId2}
                                  disabled={props.taskDetails.endorsementSatusForEndorser2 === 'Approved' ? true : false}
                                />
                              </div>


                              <div className="xl:col-span-2 md:col-span-6 col-span-12 mt-3">
                                <div className="col-span-12 flex justify-end">
                                  <p>Status:{props.taskDetails.endorsementSatusForEndorser2}</p>
                                </div>
                                <div className="col-span-12 flex justify-end">
                                  {props.isLoadingSendMail2 ? (
                                    <div className="relative w-[300px]">
                                      <button className={"text-xl font-bold text-white btn-blue-border w-full bg-blue-500 p-4 rounded-md hover:bg-blue-600 focus:outline-none"}>
                                        <span className="absolute left-[15px] top-1/2 transform -translate-y-1/2">
                                          <i className="fa fa-spinner fa-spin text-white text-[17px]"></i>
                                        </span>
                                        <span className="ml-8">
                                          Loading
                                        </span>
                                      </button>
                                    </div>
                                  ) : (
                                    <button
                                      className={`text-xl font-bold text-white btn-blue-border w-[300px] bg-blue-500 p-4 rounded-md hover:bg-blue-600 focus:outline-none
                            ${props.taskDetails.endorsementSatusForEndorser2 === 'Approved' ? 'cursor-not-allowed bg-[#e9e9ea]' : ''}`}
                                      onClick={() => props.getEndorsersEmailDetailById(true, 2)}
                                      disabled={props.taskDetails.endorsementSatusForEndorser2 === 'Approved' ? true : false}
                                    >
                                      Send Mail
                                    </button>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </SectionWithBorder>

                    <div className="">
                      <div className="w-full pb-7 overflow-x-auto">
                        <table className="table-auto w-full">
                          <thead className="bg-[#f2f2f2]  border-[#757575]  text-[#757575] ">
                            <tr>
                              <th className="border px-4 py-2">Category</th>
                              <th className="border px-4 py-2">Company Name</th>
                              <th className="border px-4 py-2">Representative Name</th>
                              <th className="border px-4 py-2">Date</th>
                              <th className="border px-4 py-2">Status</th>
                              <th className="border px-4 py-2">Comments</th>
                            </tr>
                          </thead>
                          <tbody>
                            {props.historyLogDetail && props.historyLogDetail.map((item, index) => (
                              <tr key={index}>
                                <td className="border px-4 py-2 text-center">{item.endorserCategory ? item.endorserCategory : '-'}</td>
                                <td className="border px-4 py-2 text-center">{item.companyName ? item.companyName : '-'}</td>
                                <td className="border px-4 py-2 text-center">{item.representativeName ? item.representativeName : '-'}</td>
                                <td className="border px-4 py-2 text-center">{item.issuedDate ? moment(item.issuedDate).format("MM/DD/YYYY") : '-'}</td>
                                <td className="border px-4 py-2 text-center">{item.endorsementStatus ? item.endorsementStatus : '-'}</td>
                                <td className="border px-4 py-2 text-center">{item.endorserComment ? item.endorserComment : '-'}</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </>

                  : <>
                    <SectionWithBorder title="Renew Membership Information">
                      <div className="grid grid-cols-12 gap-6  w-full items-center pt-7">

                        <div className="xl:col-span-6 md:col-span-6 col-span-12">
                          <div className="grid grid-cols-12 gap-6">
                            <div className="xl:col-span-12 md:col-span-12 col-span-12">
                              <label
                                className="filter-lable block font-medium 2xl:mb-1 lg:[0.25rem] theme-color min-h-30"
                                htmlFor="default"
                              >
                                Representation/Contact Person Name <span className="text-[#C00000]">*</span>
                              </label>

                              <input
                                id="default"
                                className="form-input rounded-none w-full shadow-red py-3 text-lg text-[#757575]"
                                type="text"
                                name="representativeName"
                                value={props.taskDetails.representativeName}
                                onChange={(event) => props.handleChange(event)}
                                validateField={() => props.validateField("organisationName")}
                              />

                              <ValidationText error={props.validState.error.organisationName} />
                            </div>
                          </div>
                        </div>
                        <div className="xl:col-span-6 md:col-span-6 col-span-12">
                          <div className="grid grid-cols-12 gap-6">
                            <div className="xl:col-span-12 md:col-span-12 col-span-12">
                              <label
                                className="filter-lable block font-medium 2xl:mb-1 lg:[0.25rem] theme-color min-h-30"
                                htmlFor="default"
                              >
                                Designation  <span className="text-[#C00000]">*</span>
                              </label>
                              <input
                                placeholder=""
                                className="form-input rounded-none w-full shadow-red py-3 text-lg text-[#757575]"
                                type="text"
                                name="designation"
                                value={props.taskDetails.designation}
                                onChange={(event) => props.handleChange(event)}
                                identity="OrganisationName"
                              // validState={props.validState}
                              // validateField={() => props.validateField("organisationName")}
                              />
                              <div>
                                <ValidationText error={props.validState.error.organisationUEN} />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="xl:col-span-6 md:col-span-6 col-span-12">
                          <div className="grid grid-cols-12 gap-6">
                            <div className="xl:col-span-12 md:col-span-12 col-span-12">
                              <label
                                className="filter-lable block font-medium 2xl:mb-1 lg:[0.25rem] theme-color min-h-30"
                                htmlFor="default"
                              >
                                Email Address
                              </label>
                              <input
                                placeholder=""
                                className="form-input rounded-none w-full shadow-red py-3 text-lg text-[#757575]"
                                type="text"
                                name="emailAddress"
                                value={props.taskDetails.emailAddress}
                                onChange={(event) => props.handleChange(event)}
                                identity="OrganisationName"
                              // validState={props.validState}
                              // validateField={() => props.validateField("organisationName")}
                              />
                            </div>
                            <ValidationText error={props.validState.error.paidUpCapital} />
                          </div>
                        </div>
                        <div className="xl:col-span-6 md:col-span-6 col-span-12">
                          <div className="grid grid-cols-12 gap-6">
                            <div className="xl:col-span-12 md:col-span-12 col-span-12">
                              <label
                                className="filter-lable block font-medium 2xl:mb-1 lg:[0.25rem] theme-color min-h-30"
                                htmlFor="default">
                                Insurance Company Name
                              </label>
                              <input
                                placeholder=""
                                className="form-input rounded-none w-full shadow-red py-3 text-lg text-[#757575]"
                                type="text"
                                name="insuranceCompanyName"
                                value={props.taskDetails.insuranceCompanyName}
                                onChange={(event) => props.handleChange(event)}
                                identity="OrganisationName"
                              // validState={props.validState}
                              // validateField={() => props.validateField("organisationName")}
                              />
                              {/* <div>
                          <ValidationText error={props.validState.error.incorporationDate} />
                        </div> */}
                            </div>
                          </div>
                        </div>

                        <div className="xl:col-span-6 md:col-span-6 col-span-12">
                          <div className="grid grid-cols-12 gap-6">
                            <div className="xl:col-span-12 md:col-span-12 col-span-12">
                              <label
                                className="filter-lable block font-medium 2xl:mb-1 lg:[0.25rem] theme-color min-h-30"
                                htmlFor="default"
                              > Policy No
                              </label>
                              <input
                                placeholder=""
                                className="form-input rounded-none w-full shadow-red py-3 text-lg text-[#757575]"
                                type="text"
                                name="policyNumber"
                                value={props.taskDetails.policyNumber}
                                onChange={(event) => props.handleChange(event)}
                                identity="OrganisationName"
                              // validState={props.validState}
                              // validateField={() => props.validateField("organisationName")}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="xl:col-span-6 md:col-span-6 col-span-12">
                          <div className="grid grid-cols-12 gap-6">
                            <div className="xl:col-span-12 md:col-span-12 col-span-12">
                              <label
                                className="filter-lable block font-medium 2xl:mb-1 lg:[0.25rem] theme-color min-h-30"
                                htmlFor="default"
                              >
                                Expiry Date

                              </label>

                              <input
                                id="default"
                                className="date-pickers form-input rounded-none w-full shadow-red py-3 text-lg"
                                type="date"
                                name="expiryDate"
                                max={moment(new Date()).format("yyyy-MM-DD")}
                                value={props.taskDetails.expiryDate !== '1900-01-01T00:00:00' ? moment(
                                  props.taskDetails.expiryDate
                                ).format("yyyy-MM-DD") : null}
                                onChange={(event) => props.handleChange(event)}
                              // disabled={true}
                              />

                            </div>
                          </div>
                        </div>
                      </div>
                    </SectionWithBorder>
                  </>
              }
              {props.taskDetails.transactionTypeId === 3 ?
                <>
                  {/* //Council Approval */}
                  <SectionWithBorder title="Council Approval">
                    <div className="">
                      <div className="grid grid-cols-12 gap-6 w-full items-center pt-3 pb-7 mt-5">
                        <div className="xl:col-span-6 md:col-span-6 col-span-12">
                          <label
                            className="filter-lable block font-medium 2xl:mb-1 lg:[0.25rem] theme-color min-h-30"
                            htmlFor="default"
                          >
                            Approval Date{" "}
                            <span className="text-[#C00000]">*</span>
                          </label>
                          <input
                            id="default"
                            className="date-pickers form-input rounded-none w-full shadow-red py-3 text-lg"
                            type="date"
                            name="approvalDate"
                            //max={moment(new Date()).format("yyyy-MM-DD")}
                            value={props.taskDetails.approvalDate ? moment(
                              props.taskDetails.approvalDate
                            ).format("yyyy-MM-DD") : moment(new Date()).format("yyyy-MM-DD")}
                            onChange={(event) => props.handleChange(event)}
                          //onBlur={() => props.validateField("incorporationDate")}
                          />
                          <div>
                            {" "}
                            <ValidationText
                              error={props.validState.error.incorporationDate}
                            />{" "}
                          </div>
                          <ValidationText
                            error={
                              props.validState.error
                                .incorporationDate
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </SectionWithBorder>
                </>

                :

                <SectionWithBorder title="Council Approval">
                  <div className="">
                    <div className="grid grid-cols-12 gap-6 w-full items-center pt-3 pb-7 mt-5">
                      <div className="xl:col-span-6 md:col-span-6 col-span-12">
                        <label
                          className="filter-lable block font-medium 2xl:mb-1 lg:[0.25rem] theme-color min-h-30"
                          htmlFor="default"
                        >
                          Subscription Start Date{" "}
                          <span className="text-[#C00000]">*</span>
                        </label>
                        <input
                          id="default"
                          className="date-pickers form-input rounded-none w-full shadow-red py-3 text-lg"
                          type="date"
                          name="approvalDate"
                          //max={moment(new Date()).format("yyyy-MM-DD")}
                          value={props.taskDetails.approvalDate ? moment(props.taskDetails.approvalDate).format("yyyy-MM-DD") : moment(new Date()).format("yyyy-MM-DD")}
                          onChange={(event) => props.handleChange(event)}
                        //onBlur={() => props.validateField("incorporationDate")}
                        />
                        <div>
                          {" "}
                          <ValidationText
                            error={props.validState.error.incorporationDate}
                          />{" "}
                        </div>
                        <ValidationText
                          error={
                            props.validState.error
                              .incorporationDate
                          }
                        />
                      </div>

                      <div className="xl:col-span-6 md:col-span-6 col-span-12">
                        <label
                          className="filter-lable block font-medium 2xl:mb-1 lg:[0.25rem] theme-color min-h-30"
                          htmlFor="default"
                        >
                          Committee Approval Date{" "}
                          <span className="text-[#C00000]">*</span>
                        </label>
                        <input
                          id="default"
                          className="date-pickers form-input rounded-none w-full shadow-red py-3 text-lg"
                          type="date"
                          name="committeeApprovalDate"
                          //max={moment(new Date()).format("yyyy-MM-DD")}
                          value={props.taskDetails.committeeApprovalDate ? moment(props.taskDetails.committeeApprovalDate).format("yyyy-MM-DD") : moment(new Date()).format("yyyy-MM-DD")}
                          onChange={(event) => props.handleChange(event)}
                        //onBlur={() => props.validateField("incorporationDate")}
                        />
                        <div>
                          {" "}
                          <ValidationText
                            error={props.validState.error.incorporationDate}
                          />{" "}
                        </div>
                        <ValidationText
                          error={
                            props.validState.error
                              .incorporationDate
                          }
                        />
                      </div>
                    </div>
                  </div>
                </SectionWithBorder>
              }
            </>
          }
        </div>

        <div className="grid grid-cols-12 gap-6">
          <div className="2xl:col-span-12 lg:col-span-12 col-span-12 gap-6 pb-10 ">
            <div className="flex items-center justify-between">
              <div className="flex items-center justify-between">
                {props.isLoadingClarification ? (
                  <div className="py-2 w-[250px] btn btn-lighblue text-lg text-white font-bold ">
                    <ButtonLoader className="buttonload group relative rounded-md btn btn-lighblue text-xl text-white focus:outline-none" />
                  </div>
                ) : (
                  <button
                    className={`py-2 w-[250px] btn btn-lighblue text-lg rounded-md text-white font-bold `}
                    // onClick={() => props.sendClarificationMailToMember()}
                    onClick={() => {
                      props.setSendClarificationsFlag();
                    }}
                  >
                    Send Clarification
                  </button>
                )}
              </div>

              <div className="flex items-center justify-between">
                {props.isLoadingReject ? (
                  <div className="w-[233px] quatation-send-btn">
                    <ButtonLoader className="buttonload group relative rounded-md btn btn-red text-xl text-white focus:outline-none" />
                  </div>
                ) : (
                  <button
                    className={`py-2 w-[250px] btn btn-red col-span-2 text-lg text-xl rounded-md text-white font-bold`}
                    onClick={() => props.rejectApplicationForMember()}
                  >
                    Reject
                  </button>
                )}
              </div>
              {props.taskDetails.transactionTypeId === 3 ?

                <div className="flex items-center justify-between">
                  {props.isLoadingApproveRenew ? (
                    <div className="w-[233px] quatation-send-btn">
                      <ButtonLoader className="buttonload group relative rounded-md btn bg-[#6cbf6c] text-xl text-white focus:outline-none" />
                    </div>
                  ) : (
                    <button
                      //className={`col-end-9 py-2 col-span-2 text-xl rounded-md font-bold text-white btn bg-[#6cbf6c] text-red w-[250px]`}
                      className={`col-end-9 py-2 col-span-2 text-xl rounded-md font-bold text-white btn 
        ${!props.taskDetails.paid ? 'bg-gray-400 cursor-not-allowed' : 'bg-[#6cbf6c]'} text-red w-[250px]`}
                      onClick={() => props.approveRenewMembershipRequest(props.taskDetails
                        .bookingId,
                        props.taskDetails
                          .memberId,
                        props.taskDetails
                          .membershipPaymentTypeId,
                        props.taskDetails
                          .financeAdhocInvoiceId)}
                      disabled={props.taskDetails.paid === false}
                    >
                      Approve
                    </button>
                  )}
                </div>
                :
                <div className="flex items-center justify-between">
                  {props.isLoadingApprove ? (
                    <div className="w-[233px] quatation-send-btn">
                      <ButtonLoader className="buttonload group relative rounded-md btn bg-[#6cbf6c] text-xl text-white focus:outline-none" />
                    </div>
                  ) : (
                    <button
                      className={`col-end-9 py-2 col-span-2 text-xl rounded-md font-bold text-white btn bg-[#6cbf6c] text-red w-[250px]`}
                      onClick={() => props.sendPaymentLink()}
                    >
                      Send Payment Link
                    </button>
                  )}
                </div>}

              {/* <div className="flex items-center justify-between">
                {props.isLoadingApprove ? (
                  <div className="w-[233px] quatation-send-btn">
                    <ButtonLoader />
                  </div>
                ) : (
                  <button
                    className={`col-end-9 py-2 col-span-2 text-xl font-bold text-white btn bg-[#6cbf6c] text-red w-[250px] ${!endorserBothValid
                      ? "opacity-50 cursor-not-allowed"
                      : null
                      }`}
                    onClick={() => props.addEditMembershipTaskAndSendPaymentLink()}
                    disabled={!endorserBothValid}
                  >
                    Send Payment Link
                  </button>
                )}
              </div> */}
            </div>
          </div>
        </div>

        {/*Footer Button End*/}
        <div className="flex items-center justify-between pb-6">
          <button
            className="text-lg py-2 font-bold btn btn-gray w-auto text-white"
            onClick={() => props.closeModalOnBack()}
          >
            Back
          </button>
        </div>
        {/*Footer Button End*/}

      </div>
    </ModalBasic>

  );
};
export default MembershipTaskListProcess;