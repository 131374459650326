import React, { Component } from "react";
import CommunitySpotlightServices from "../../../services/axiosServices/apiServices/CommunitySpotlightServices";
import SwalServices from "../../../services/swalServices/SwalServices";
import moment from "moment";
import ImageCropperWithUpload from "../../../components/ImageUpload/UploadImage";
import ValidationText from "../../../utils/validation/ValidationText";
import CommonServices from "../../../services/axiosServices/apiServices/CommonServices";
import CKClassicEditor from "../../../components/ckEditor/CKClassicEditor";
import { checkParameters, decryptAES } from "../../../utils/Encryption";
import { APIURL, GetImage } from "../../../services/axiosServices/ApiEndPoints";
import { Navigate } from "react-router-dom";
import { CommonValidationMessages } from "../../../utils/Messages";
import { isValidForm } from "../../../utils/validation/CommonValidator";

export default class AddEditSpotlight extends Component {
  constructor(props) {
    super(props);
    this.CommunitySpotlightServices = new CommunitySpotlightServices();
    this.commonServices = new CommonServices();
    this.swalServices = new SwalServices();
    this.state = {
      communitySpotlightDetails: {
        spotlightId: 0,
        spotlightTitle: "",
        description: "",
        startDate: "",
        endDate: "",
      },
      validationRule: {
        spotlightTitle: [
          {
            type: 'require',
            message: CommonValidationMessages.FieldRequired.replace('{0}', 'Title'),
          },
        ],
        description: [
          {
            type: 'require',
            message: CommonValidationMessages.FieldRequired.replace('{0}', 'description')
          }
        ],
      },
      validState: {
        isValid: true,
        error: {}
      },
      

      isLoading: false,
    };
    this.handleChange = this.handleChange.bind(this);
  }
  isValidateAllFields = () => {
    const newValidState = isValidForm(this.state.communitySpotlightDetails, this.state.validationRule, this.state.validState);
    this.setState({ validState: newValidState });
    return newValidState.isValid;
  }

  addEditCommunitySpotlight = () => {
    let request = {
      spotlightId:
        this.state.communitySpotlightDetails.spotlightId === undefined
          ? 0
          : this.state.communitySpotlightDetails.spotlightId,
      spotlightTitle: this.state.communitySpotlightDetails.spotlightTitle,
      description: this.state.communitySpotlightDetails.description,
      redirectionLink: this.state.communitySpotlightDetails.redirectionLink === null ? "" : this.state.communitySpotlightDetails.redirectionLink,
      spotlightImage: this.state.communitySpotlightDetails.spotlightImage,
      orderingPosition: this.state.communitySpotlightDetails.orderingPosition,
      status: this.state.communitySpotlightDetails.status > 0 ? this.state.communitySpotlightDetails.status : 0,
    };

    this.CommunitySpotlightServices.addEditCommunitySpotlight(request).then(
      (response) => {
        if (response.statusCode === 200 && response.responseItem != null) {
          if (response.responseItem.responseContent.hasError === false) {
            this.setState({ addEditCommunitySpotlight: response.AddEditCommunitySpotlight, });
            this.swalServices.Success(response.message);
            this.setState({ redirect: "/ManageSpotlights" });
          } else {
            this.swalServices.Error(response.responseItem.responseContent.errorMessage);
          }
        } else {
          this.swalServices.Error(response.message);
        }
      }
    );
  };

  getCommunitySpotlight = (spotlightId) => {
    this.CommunitySpotlightServices.getCommunitySpotlightById(spotlightId).then((response) => {
      if (response.statusCode === 200 && response.responseItem != null) {
        if (response.responseItem.responseContent != null) {
          this.setState({
            communitySpotlightDetails: response.responseItem.responseContent,
          });
        }
      } else {
        this.swalServices.Error(response.message);
      }
      this.setState({ isLoading: false });
    });
  };

  handleChange(event) {
    let value, name;
    if (typeof event === 'string') {
      value = event;
      name = 'description';
    } else if (event.target) {
      value = event.target.value;
      name = event.target.name;
    } else {
      return;
    }

    let detail = this.state.communitySpotlightDetails;
    detail[name] = value;
    this.setState({ communitySpotlightDetails: { ...detail } });
  }

  componentDidMount() {
    let params = new URLSearchParams(window.location.search);
    let spotlightId = params.get("");
    if (spotlightId && (spotlightId = checkParameters(spotlightId, "Number"))) {
      if (spotlightId != null && spotlightId > 0) {
        this.getCommunitySpotlight(spotlightId);
      }
    }
  }

  back = () => {
    this.setState({ redirect: "/ManageSpotlights" });
  }

  saveMainImage = (file, keyId, imageIdentity) => {
    file.storagePath = "SpotlightImage";
    this.commonServices.storeBase64Image(file).then((response) => {
      if (response != null) {
        this.swalServices.Success("Logo Uploaded");
        if (file.storagePath === "SpotlightImage") {
          let detail = this.state.communitySpotlightDetails;
          detail["spotlightImage"] = response.fileName;
          this.setState({ communitySpotlightDetails: { ...detail } });
        }
      }
      else {
        this.swalServices.Error(response.message);
      }
    })
  }
  addNew = () => {
    let detail = this.state.communitySpotlightDetails;
    if (this.isValidateAllFields()) {
      this.setState({ communitySpotlightDetails: { ...detail } });
      this.addEditCommunitySpotlight();
    }
  }
  render() {
    if (this.state.redirect != null) {
      return <Navigate to={this.state.redirect} />;
    }
    return (
      <div className="main-body-section">
        <div className="bg-white">
          <div className="px-7 py-4 pt-10">
            <div className="mb-16">
              <div className="grid grid-cols-12 gap-6">
                <div className="xl:col-span-6 lg:col-span-6 col-span-12">
                  <div className="grid grid-cols-12 gap-6">
                    <div className="col-span-12 mb-2 input-sec-texts">
                      <div className="filter-types">
                        <label
                          className="filter-lable block font-medium mb-3 theme-color min-h-30"
                          htmlFor="title"
                        >
                          Title <span className="text-[#C00000]">*</span>
                        </label>
                        <input
                          id="spotlightTitle"
                          className="form-input rounded-none w-full shadow-red py-3 text-lg"
                          name="spotlightTitle"
                          type="text"
                          onChange={(e) => this.handleChange(e)}
                          value={this.state.communitySpotlightDetails.spotlightTitle}
                        />
                        <div>
                          <ValidationText
                            error={this.state.validState.error.spotlightTitle}
                          />
                        </div>
                      </div>

                    </div>
                    <div className="col-span-12 mb-2 input-sec-texts" style={{ paddingLeft: 0, paddingRight: 40 }}>
                      <div className="filter-types">
                        <label
                          className="filter-lable block font-medium mb-3 theme-color min-h-30"
                          htmlFor="description"
                        >
                          Description <span className="text-[#C00000]">*</span>
                        </label>
                        <div className="ck-editor-sec">
                          <CKClassicEditor
                            key={"description"}
                            onChange={(event, editor) => {
                              const data = editor.getData();
                              this.handleChange(data);
                            }}
                            data={this.state.communitySpotlightDetails.description}
                            onBlur={(event, editor) => {
                              this.validateField("description");
                            }}
                          />
                          <div>
                            <ValidationText
                              error={this.state.validState.error.description}
                            />
                          </div>
                          <div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-span-12 mb-2 input-sec-texts">
                      <div className="filter-types">
                        <label
                          className="filter-lable block font-medium mb-3 theme-color min-h-30"
                          htmlFor="redirectionLink"
                        >
                          Redirection Link
                        </label>
                        <input
                          id="redirectionLink"
                          className="form-input rounded-none w-full shadow-red py-3 text-lg"
                          name="redirectionLink"
                          type="text"
                          onChange={(e) => this.handleChange(e)}
                          value={
                            this.state.communitySpotlightDetails.redirectionLink
                          }
                        />
                      </div>
                    </div>
                    {
                      this.state.communitySpotlightDetails.spotlightId ? (
                        <div className="col-span-12 mb-2 input-sec-texts" style={{ paddingLeft: 0, paddingRight: 40 }}>
                        <div className="filter-types">
                          <label
                            className="filter-lable block font-medium mb-3 theme-color min-h-30"
                            htmlFor="orderingPosition"
                          >
                            Ordering Position <span className="text-[#C00000]">*</span>
                          </label>
                         <input
                            id="orderingPosition"
                            className="form-input rounded-none w-full shadow-red py-3 text-lg"
                            name="orderingPosition"
                            type="number"
                            onChange={(e) => this.handleChange(e)}
                            value={
                              this.state.communitySpotlightDetails.orderingPosition
                            }
                          />
                        </div>
                      </div>
                      ): null
                    }
                   
                  </div>
                </div>

                <div className="xl:col-span-6 lg:col-span-6 col-span-12 mt-10 ">
                  <div className="filter-types">
                    <ImageCropperWithUpload
                      key="Main"
                      height={1080}
                      width={1920}
                      isCheckValidation={true}
                      MaxFileSize={2097152}
                      MaxFileSizeMessage={"2MB"}
                      onSaveImage={this.saveMainImage}
                      imageIdentity="spotlightImage"
                      intitImage={this.state.communitySpotlightDetails.spotlightImage}
                      aspactRatio={1920 / 1080}
                      uploadBtn={"Upload Spotlight Image"}
                      dimensionText={
                        "(Dimension: 1920px by 1080px Maximum Size: 2MB)"
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-wrap justify-between space-x-10 mt-12">
          <div>
            <button className="w-32 px-7 py-2 btn-sm btn-gray text-lg text-white" onClick={() => this.back()}>
              Back
            </button>
          </div>
          <div>
            <button
              className="w-32 px-7 py-2 btn-sm btn-red text-lg text-white"
              onClick={() => this.addNew()}
            >
              Publish
            </button>
          </div>
        </div>
      </div>
    );
  }
}
